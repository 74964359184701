<template>
	<b-modal
		centered
		no-close-on-backdrop
		size="lg"
		title="REGISTRAR FECHA CLASIFICADA"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<h2 class="title-form">1. Informacion general</h2>
				<b-row>
					<b-col cols="6">
						<b-form-group label="Torneo">
							<v-select
								class="border-primary rounded"
								label="name"
								placeholder="Seleccionar torneo"
								:clearable="false"
								:options="tournamentOpts"
								:reduce="(opt) => opt.id"
								v-model="form.tournament"
								disabled
							/>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label="Fecha">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-form-datepicker
									class="border-primary"
									locale="en-US"
									placeholder="Seleccionar fecha"
									:date-format-options="{ month: 'numeric', day: 'numeric', year: 'numeric' }"
									:max="curTournament.end_date"
									:min="curTournament.start_date"
									v-model="form.date"
									v-if="curTournament"
								/>
								<b-form-datepicker
									class="border-primary"
									locale="en-US"
									placeholder="Seleccionar fecha"
									disabled
									v-else
								/>
								<small class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label="Coliseo">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									class="border-primary rounded"
									label="name"
									placeholder="Seleccionar coliseo"
									:clearable="false"
									:options="colliseumOpts"
									:reduce="(opt) => opt.id"
									:disabled="form.tournament == null"
									v-model="form.colliseum"
								/>
								<small class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label="Etapa">
							<v-select
								class="border-primary rounded"
								label="name"
								placeholder="Seleccionar etapa"
								:clearable="false"
								:options="stageOpts"
								:reduce="(opt) => opt.id"
								:selectable="(opt) => Number(opt.amount) > 0"
								v-model="form.stage"
								disabled
							/>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label="Tipo">
							<v-select
								class="border-primary rounded"
								label="name"
								placeholder="Seleccionar tipo"
								:clearable="false"
								:options="typeOpts"
								:reduce="(opt) => opt.id"
								v-model="form.type"
								disabled
							/>
						</b-form-group>
					</b-col>
					<b-col cols="6"> </b-col>
					<b-col cols="6">
						<b-row>
							<b-col cols="12" md="6">
								<b-form-group label="Metodo">
									<v-select
										class="border-primary rounded"
										label="name"
										placeholder="Seleccionar metodo"
										:clearable="false"
										:options="methodOpts"
										:reduce="(opt) => opt.id"
										v-model="form.method"
										@input="form.weight = null"
										disabled
									/>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="6">
								<b-form-group label="Peso" v-if="form.method == 2">
									<b-input-group prepend="Kg">
										<b-form-input
											class="border-primary rounded"
											type="number"
											v-model="form.weight"
											placeholder="Agregar peso"
											disabled
										/>
									</b-input-group>
								</b-form-group>
								<b-form-group label="Peso" v-else>
									<b-input-group class="border-primary rounded" prepend="Kg">
										<b-form-input type="number" disabled />
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>

					<b-col cols="6">
						<b-row>
							<b-col cols="6">
								<b-form-group label="Cantidad de frentes">
									<b-form-input
										class="border-primary rounded"
										type="number"
										v-model="form.fronts"
										placeholder="n.º frentes"
										disabled
									/>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group label="Ejemplares por frente">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-input
											class="border-primary rounded"
											type="number"
											v-model="form.specimensPerFront"
											placeholder="n.º ejemplares"
										/>
										<small class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</small>
									</ValidationProvider>
								</b-form-group>
							</b-col>
							<!-- <b-col cols="4">
								<b-form-group label="Ejemplares por frente,llevar">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-input
											class="border-primary rounded"
											type="number"
											v-model="form.specimensPerFront"
											placeholder="n.º ejemplares"
										/>
										<small class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</small>
									</ValidationProvider>
								</b-form-group>
							</b-col> -->
						</b-row>
					</b-col>
				</b-row>
				<h2 class="title-form">2. Colaboradores</h2>
				<b-row class="mb-1 mt-1" v-for="collaborator in form.collaborators" :key="collaborator.roleId">
					<b-col cols="6">
						<div style="height: 100%" class="d-flex align-items-center">
							<p class="title-form mb-0" style="font-size: 16px; text-tranform: uppercase">
								{{ collaborator.roleName }} :
							</p>
						</div>
					</b-col>
					<b-col cols="6">
						<v-select
							class="border-primary rounded"
							label="name"
							placeholder="Seleccionar colaborador"
							:clearable="false"
							:options="collaboratorOpts"
							:reduce="(opt) => opt.id"
							v-model="collaborator.value"
						/>
					</b-col>
				</b-row>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="primary" @click="createEvent">Guardar</b-button>
		</template>
		<ManageFrontModal :show="manageFront.show" :info="{}" @closing="manageFront.show = !manageFront.show" />
	</b-modal>
</template>

<script>
import moment from "moment";
import tournamentService from "@/services/tournament/default.service";

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import ManageFrontModal from "./ManageFrontModal.vue";

export default {
	components: { SpecimenPlate, ManageFrontModal },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tournamentOpts: [],
		stageOpts: [],
		methodOpts: [
			{ name: "AL PESO", id: 2 },
			{ name: "TAPADA", id: 1 },
		],
		typeOpts: [],
		collaboratorOpts: [],
		colliseumOpts: [],
		form: {
			tournament: null,
			date: null,
			type: null,
			stage: null,
			method: null,
			weight: null,
			colliseum: null,
			collaborators: [],
			responsible: null,
			fronts: null,
			specimensPerFront: null,
		},
		manageFront: {
			show: false,
		},
	}),
	computed: {
		curTournament() {
			if (this.tournamentOpts.length == 0) return null;
			let res = this.tournamentOpts.find((t) => t.id == this.form.tournament);
			if (this.info.data.stage_id == 4) {
				this.form.date = res.end_date;
			}

			return res;
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.isLoading = false;
			this.form = {
				tournament: null,
				date: null,
				type: null,
				stage: null,
				method: null,
				weight: null,
				colliseum: null,
				collaborators: [],
				responsible: null,
				fronts: null,
				specimensPerFront: null,
			};
			this.$emit("closing");
		},
		async getOpts() {
			this.isLoading = true;
			const opts = [
				tournamentService.getTournamentOpts(),
				tournamentService.getCollaboratorRoles(),
				tournamentService.getCollaborators(),
				tournamentService.getTournamentTypes(),
			];
			const data = await Promise.all(opts);
			this.tournamentOpts = data[0].data.data;
			this.form.collaborators = data[1].data.data.map((role) => ({
				roleId: role.id,
				roleName: role.name,
				user: null,
			}));
			this.collaboratorOpts = data[2].data.data;
			this.typeOpts = data[3].data.data;
			this.isLoading = false;
		},
		async getColiseumOpts() {
			this.isLoading = true;
			const opts = [
				tournamentService.getColiseumByTournament({
					tournament_id: this.form.tournament,
				}),
				tournamentService.getStagesByTournament({
					tournament_id: this.form.tournament,
				}),
			];
			const data = await Promise.all(opts);
			this.colliseumOpts = data[0].data.data;
			this.stageOpts = data[1].data.data.map((s) => ({
				...s,
				name: `${s.name} - S./ ${s.amount}`,
			}));
			this.isLoading = false;
		},
		async createEvent() {
			if (!(await this.$refs.form.validate())) return;
			this.isLoading = true;
			let collaborators = this.form.collaborators.map((collaborator) => ({
				role_id: collaborator.roleId,
				user_id: collaborator.value,
			}));
			await tournamentService.createEvent({
				tournament_id: this.form.tournament,
				date: moment(this.form.date).format("YYYY-MM-DD"),
				coliseum_id: this.form.colliseum,
				type_id: this.form.type,
				stage_id: this.form.stage,
				method_id: this.form.method,
				weight: this.form.weight,
				responsible_id: this.form.responsible,
				specimens_required: this.form.specimensPerFront,
				quantity_fronts: this.form.fronts,
				collaborators,
				event_temporary_id: this.info.id,
			});
			this.handleHidden();
			this.$emit("refresh");
			this.isLoading = false;
		},
		addFront() {
			this.form.fronts.push({ id: Math.random().toString(), value: null });
		},
		removeFront(idx) {
			this.form.fronts.splice(idx, 1);
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val;
				// populate existing data
				this.form.tournament = this.info.data.tournament_id;
				this.form.method = this.info.data.method;
				this.form.weight = this.info.data.weight;
				this.form.colliseum = this.info.data.coliseum_id;
				this.form.type = this.info.data.type_id;
				this.form.stage = this.info.data.stage_id;

				this.form.fronts = this.info.data.quantity_fronts;
				this.getOpts();
				this.getColiseumOpts();
			}
		},
	},
};
</script>
<style scoped>
.title-form {
	font-family: "Rubik" !important;
	font-size: 20px;
}
</style>
