<template>
	<b-modal centered title="Torneos / Asignar alias" v-model="isActive" size="sm" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<SpecimenPlate :specimen="info.specimen" v-if="info.specimen" />
			<b-form-group class="mt-2" label="Nuevo alias">
				<b-input
					:class="{ 'border-danger': !!aliasError }"
					v-model="newAlias"
					@input="() => (newAlias = newAlias.toUpperCase())"
				/>
				<small class="text-danger" v-if="!!aliasError">{{ aliasError }}</small>
			</b-form-group>
			<hr />
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="send" :disabled="isLoading">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		newAlias: "",
		aliasError: "",
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.newAlias = ""
			this.$emit("closing")
		},
		async send() {
			this.isLoading = true
			const specimens = [{ id: this.info.specimen.id, alias: this.newAlias }]
			const { data } = await tournamentService.assignSpecimenAliases({ specimens })
			if (data.length != 0) {
				this.aliasError = "El alias ya existe"
				this.isLoading = false
				return
			}
			this.$emit("assigned", this.newAlias)
			this.isLoading = false
			this.handleHidden()
			this.showToast("success", "top-right", "Torneos", "InfoIcon", "Nuevo alias correctamente asignado")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
			}
		},
	},
}
</script>
