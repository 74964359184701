<template>
	<b-modal
		no-close-on-backdrop
		centered
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
		:hide-footer="info.status_id == 3"
	>
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<h4 class="mb-2">
					Premios
					<b-button
						v-if="info.status_id != 3"
						class="btn-icon ml-1"
						variant="flat-success"
						size="sm"
						@click="awards.push({ type: null, value: 0 })"
					>
						<feather-icon icon="PlusIcon" size="18" />
					</b-button>
				</h4>
				<div style="max-height: 10rem; overflow-y: auto; overflow-x: hidden">
					<b-row class="mb-1">
						<b-col cols="5">
							<h6 class="text-center">Tipo</h6>
						</b-col>
						<b-col cols="5">
							<h6 class="text-center">Premio</h6>
						</b-col>
						<b-col cols="2"></b-col>
					</b-row>
					<b-row class="mb-1" v-for="(award, i) in awards" :key="i">
						<b-col cols="5">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-form-select
									:disabled="info.status_id == 3"
									:class="{ 'border-danger ': errors[0] }"
									:options="awardTypeOpts"
									v-model="award.type"
									@input="
										award.type == 1
											? (award.value = award.value != 0 ? award.value : 0)
											: award.value == 0
											? (award.value = '')
											: null
									"
								/>
							</ValidationProvider>
						</b-col>
						<b-col cols="5">
							<b-form-input disabled v-if="award.type == null" />
							<ValidationProvider rules="required|zero" v-slot="{ errors }" v-else-if="award.type == 1">
								<money
									v-bind="money"
									v-model.number="award.value"
									class="form-control text-center"
									
									:class="{ 'border-danger ': errors[0] }"
									placeholder="Agregar monto"
								/>
							</ValidationProvider>
							<ValidationProvider rules="required" v-slot="{ errors }" v-else>
								<b-form-input
									class="text-center"
									:class="{ 'border-danger': errors[0] }"
									v-model="award.value"
								/>
							</ValidationProvider>
						</b-col>
						<b-col cols="2">
							<b-button
								v-if="info.status_id != 3"
								class="btn-icon"
								variant="flat-danger"
								size="sm"
								@click="awards.splice(i, 1)"
							>
								<feather-icon icon="TrashIcon" size="18" />
							</b-button>
						</b-col>
					</b-row>
				</div>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="sendAwards" :disabled="isLoading">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import { Money } from "v-money"

import tournamentService from "@/services/tournament/default.service"

export default {
	components: { Money },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		money: {
			decimal: ",",
			thousands: ".",
			prefix: "S./ ",
			suffix: "",
			precision: 2,
			masked: false,
		},
		rankingOpts: [
			{ value: 1, text: "Primero" },
			{ value: 2, text: "Segundo" },
			{ value: 3, text: "Tercero" },
			{ value: 4, text: "Cuarto" },
			{ value: 5, text: "Quinto" },
			{ value: 6, text: "Sexto" },
			{ value: 7, text: "Septimo" },
			{ value: 8, text: "Ovtavo" },
		],
		ranking: null,
		comment: null,
		awardTypeOpts: [
			{ value: 1, text: "Dinero" },
			{ value: 2, text: "Objeto" },
			{ value: 3, text: "Reconocimiento" },
		],
		awards: [{ type: null, value: "" }],
		money: {
			decimal: ".",
			thousands: ",",
			prefix: "S./ ",
			suffix: "",
			precision: 2,
			masked: false,
		},
	}),
	computed: {
		readonly() {
			return this.info.awards != null
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.ranking = null
			this.comment = null
			this.awards = [{ type: null, value: null }]
			this.$emit("closing")
		},
		async sendAwards() {
			if (!(await this.$refs.form.validate())) {
				return
			}
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isLoading = true
			try {
				await tournamentService.addAwards({
					awards: this.awards.map((a) => ({ type_id: a.type, value: a.value })),
					tournament_id: this.info.tournament_id,
					tournament_event_front_id: this.info.front_id,
					award_position_id: this.info.ranking,
					description: this.comment,
				})
			} catch (error) {
				this.isLoading = false
				return
			}

			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
			await this.initAwards()
			this.showToast(
				"success",
				"top-right",
				"Torneos / Premios",
				"AlertCircleIcon",
				"Premios correctamente registrados"
			)
		},
		async initAwards() {
			const { data } = await tournamentService.getAwardsItems({ id: this.info.front_id })
			this.awards = data.map((i) => ({
				type: i.award_type_id,
				value: i.award_type_id == 1 ? parseFloat(i.value) : i.value,
			}))
			this.info.awards ? (this.info.awards.counter = this.awards.length) : null

			if (this.awards.length == 0) {
				this.awards.push({ type: null, value: 0 })
			}
		},
	},
	watch: {
		async show(val) {
			if (val) {
				this.isActive = val

				await this.initAwards()

				if (this.readonly) {
					this.comment = this.info.awards.description
					this.ranking = this.info.awards.position_id
				}
				this.title = "PREMIOS / FRENTE #" + (this.info._idx + 1)
			}
		},
	},
}
</script>
