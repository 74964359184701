<template>
	<b-modal centered hide-footer title="Fechas clasificadas" size="lg" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Torneo</b-th>
						<b-th>Fecha</b-th>
						<b-th class="text-nowrap">Etapa proveniente</b-th>
						<b-th>Registrar</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td>{{ row.date.tournament }}</b-td>
						<b-td>{{ row.date.date | myGlobalDayShort }}</b-td>
						<b-td>{{ row.date.stage }}</b-td>
						<b-td>
							<b-button
								class="text-nowrap"
								variant="primary"
								@click=";(completePending.show = !completePending.show), (completePending.info = row)"
							>
								Siguiente etapa
							</b-button>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="4">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<MassiveEventsModal
			:show="completePending.show"
			:info="completePending.info"
			@closing="completePending.show = false"
			@refresh="getRows(), $emit('refresh')"
			:disabled_price_zero="true"
			:season="{ id: null, start: null, end: null }"
		/>
	</b-modal>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import MassiveEventsModal from "./MassiveEventsModal.vue"
import CompletePendingModal from "./CompletePendingModal.vue"

export default {
	components: { CompletePendingModal, MassiveEventsModal },
	props: {
		show: Boolean,
		info: Object,
		season: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
		completePending: {
			show: false,
			info: {},
		},
	}),
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tournamentService.getPendingEvents()
			this.rows = data
			this.isLoading = false
			if (data.length == 0) {
				this.handleHidden()
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>
