<template>
	<div>
		<b-overlay :show="isLoading">
			<b-modal
				ref="modal-Create"
				@hidden="$emit('close')"
				cancel-title="Cancelar"
				scrollable
				size="md"
				no-close-on-backdrop
				:title="`${'Agregar participantes'} /  ${infoParticipant.name} /  ${infoParticipant.date}  `"
			>
				<div style="display: flex; justify-content: space-between; align-items: end">
					<div style="width: 100%">
						<div style="display: flex; gap: 0.5rem; align-items: center">
							<label for="category_id">Participantes</label>
							<b-button
								variant="success"
								class="btn-icon btn-sm"
								style="height: 1.5rem; width: 1.5rem; padding: 0"
								@click="
									openModal = true
									participants = []
								"
							>
								<feather-icon icon="PlusIcon" />
							</b-button>
						</div>

						<div
							style="
								display: flex;
								gap: 0.8rem;
								justify-content: space-between;
								width: 100%;
								padding-top: 0.5rem;
							"
						>
							<v-select
								v-model="participantSelect"
								:clearable="false"
								label="name"
								:options="optionParticipant"
								placeholder="Buscar participante..."
								@search="fetchOptionsSpecimens"
								style="width: 100%"
							/>
							<!-- <template v-slot:option="option">xdd</template> -->
							<!-- </v-select> -->

							<b-button
								variant="success"
								class="btn-icon btn-sm"
								style="height: 2.5rem"
								@click="addParticipantnew"
							>
								<feather-icon icon="PlusIcon" />
							</b-button>
						</div>
					</div>
				</div>
				<div style="padding-top: 2rem">
					<div class="containers">
						<div class="container-grid">
							<!-- <div
								style="display: flex; justify-content: center; padding: 0.7rem"
								class="text-participants"
							>
								<span style="font-size: 1rem">Grupo</span>
							</div>
							<div
								style="display: flex; justify-content: center; padding: 0.7rem"
								class="text-participant1"
							>
								<span style="font-size: 1rem">Alineamiento</span>
							</div> -->
							<div
								style="display: flex; justify-content: center; padding: 0.7rem"
								class="text-participant2"
							>
								<span style="font-size: 1rem">Participante</span>
							</div>
						</div>

						<div class="container-grid">
							<!-- <div style="display: flex; flex-direction: column">
								<div
									class="group-container"
									style="padding: 2rem; font-weight: 800; display: flex; justify-content: center"
									v-for="i in 8"
									:class="`${i % 2 == 0 ? 'bg-par' : 'bg-inpar'}`"
								>
									{{ i }}
								</div>
							</div> -->
							<!-- <div>
								<div
									class="group-alignment"
									style="padding: 0.6195rem; font-weight: 800; display: flex; justify-content: center"
									v-for="(i, index) in alignment"
									:class="['group-alignment', Math.floor(index / 2) % 2 === 0 ? 'color1' : 'color2']"
								>
									{{ i }}
								</div>
							</div> -->
							<div>
								<!-- <div
									class="group-participant contenedor"
									style="
										height: 38.65px;
										font-weight: 800;
										display: flex;
										align-items: center;
										padding-left: 0.5rem;
										padding-right: 0.5rem;
									"
									v-for="(part, index) in participant"
									:key="index"
									@drop="drop(index, $event, part)"
									@dragover.prevent
									data-col="participantsCol"
									:class="['group-alignment', Math.floor(index / 2) % 2 === 0 ? 'color1' : 'color2']"
								>
									<div
										style="display: flex; justify-content: space-between; width: 100%"
										:class="{ 'cursor-move': part.participant_id != null && part.is_played == 0 }"
										:draggable="part.is_played == 0 || part.participant_id != null"
										@dragstart="drag(part.id, index, $event)"
										:data-participant_Id="part.id"
										:data-element_index="index"
										:key="index"
									>
										<div>
											<span class="transparents">.</span>
											<span>{{ part.participant }}</span>
										</div>
										<div class="mxa" v-if="part.participant && part.is_played === 0">
											<feather-icon
												v-b-tooltip.hover.top="'Eliminar'"
												icon="TrashIcon"
												class="text-danger cursor-pointer"
												size="20"
												@click="deleteParticipant(part)"
											/>
										</div>

										<div v-if="part.participant && part.is_played === 1 && part.is_winner === 1">
											<i
													v-b-tooltip.hover.top="'Victoria'"
													class="fa-solid fa-flag"
													style="color: #28c76f; font-size: 1.2rem; padding-right: 0.3rem"
												></i>
										</div>

										<div v-if="part.participant && part.is_played === 1 && part.is_winner === 0">
											<i
												v-b-tooltip.hover.top="'Derrota'"
												class="fa-solid fa-flag"
												style="color: red; font-size: 1.2rem; padding-right: 0.3rem"
											></i>
										</div>
									</div>
								</div> -->

								<div
									class="group-participant contenedor"
									style="
										height: 38.65px;
										font-weight: 800;
										display: flex;
										align-items: center;
										padding-left: 0.5rem;
										padding-right: 0.5rem;
									"
									v-for="(part, index) in getParticipant"
									:key="index"
								>
									<div
										style="display: flex; justify-content: space-between; width: 100%"
										:key="index"
									>
										<div>
											<span v-if="part.name" class="">{{ index + 1 }}.</span>
											<span>{{ part.name }}</span>
										</div>
										<div class="mxa" v-if="part.is_deleted === 0 && part.participant_id !== null">
											<feather-icon
												v-if="part.in_use !== 1"
												v-b-tooltip.hover.top="'Eliminar'"
												icon="TrashIcon"
												class="text-danger cursor-pointer"
												size="20"
												@click="deleteParticipant(part)"
											/>

											<i
												v-else
												v-b-tooltip.hover.top="'Asignado'"
												style="
													color: #8095ff;
													font-size: 1.2rem;
													font-weight: 800;
													z-index: 999;
												"
												class="fa-solid fa-check"
											></i>
										</div>

										<!-- <div v-if="par">
											<i
												v-b-tooltip.hover.top="'Victoria'"
												class="fa-solid fa-flag"
												style="color: #28c76f; font-size: 1.2rem; padding-right: 0.3rem"
											></i>
										</div>

										<div v-if="part.participant && part.is_played === 1 && part.is_winner === 0">
											<i
												v-b-tooltip.hover.top="'Derrota'"
												class="fa-solid fa-flag"
												style="color: red; font-size: 1.2rem; padding-right: 0.3rem"
											></i>
										</div> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<template #modal-footer>
					<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
					<b-button variant="primary" @click="registerParticipant">Guardar</b-button>
				</template>
			</b-modal>

			<!-- MODAL PARA AGREGAR LOS PARTICIPANTES -->
			<b-modal
				@ok.prevent="saveParticipants"
				title="Agregar participante"
				v-model="openModal"
				ok-title="Guardar"
				cancel-title="Cancelar"
				scrollable
				no-close-on-backdrop
			>
				<!-- <validation-observer tag="b-row" ref="form"> -->
				<b-row>
					<b-col cols="8" md="10">
						<!-- <validation-provider rules="required" v-slot="{ errors, valid }"> -->
						<b-form-group label="Nombre del participante" label-for="name">
							<b-form-input
								placeholder="Nombre del participante"
								v-model.trim="name"
								@input="(val) => (name = name.toUpperCase())"
								:input="debounceInput"
								@keyup.enter="addParticipant()"
							/>
							<!-- <span style="color: red">{{ errors[0] }}</span> -->
						</b-form-group>
						<!-- </validation-provider> -->
					</b-col>
					<b-col cols="4" md="2" class="margin-btn-add">
						<b-button
							type="button"
							variant="primary"
							@click="addParticipant()"
							:disabled="name.length < 4"
							size="sm"
						>
							<feather-icon class="icon" icon="PlusIcon" :size="width < 600 ? '16' : '20'" />
						</b-button>
					</b-col>
				</b-row>
				<!-- </validation-observer> -->

				<div>
					<h5 v-if="participants.length > 0" class="mb-2">Participantes: {{ participants.length }}</h5>
					<ul class="grid-participants-add">
						<li
							v-for="(parti, index) in participants"
							:key="`parti-${index}`"
							class="d-flex justify-content-between align-items-center"
						>
							<p class="text-dark font-weight-bold">
								<!-- <span class="text-dark">{{ index + 1 }}.</span>  -->
								{{ parti }}
							</p>
							<feather-icon
								class="icon text-danger cursor-pointer"
								icon="XCircleIcon"
								:size="width < 600 ? '16' : '20'"
								@click="deleteParticipantnew(parti)"
							/>
						</li>
					</ul>
				</div>
			</b-modal>
		</b-overlay>
	</div>
</template>
<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import tournamentService from "@/views/amg/tournaments/tournament.service.js"
import { ValidationProvider } from "vee-validate"
import participantService from "@/views/brain/tournament/views/participants/participants.service.js"
import draggable from "vuedraggable"

export default {
	props: { infoParticipant: Object },
	directives: { Ripple },
	components: {
		ValidationProvider,
		draggable,
	},
	mixins: [modalMixin],
	data() {
		return {
			isLoading: false,
			participantSelect: null,
			participant: [],
			optionParticipant: [],
			alignment: ["I", "D", "I", "D", "I", "D", "I", "D", "I", "D", "I", "D", "I", "D", "I", "D"],
			openModal: false,
			name: "",
			width: this.$store.state["app"].windowWidth,
			participants: [],
		}
	},

	async mounted() {
		this.toggleModal("modal-Create")
	},

	async created() {
		this.participant = this.generateNullParticipants()
		await this.getByParticipants()
	},
	computed: {
		// showButton() {
		// 	return !this.participant.every((participant) => participant && participant.id && participant.participant_id)
		// },
	},

	computed: {
		getParticipant() {
			return this.participant.filter((part) => part.is_deleted === 0)
		},
	},
	methods: {
		closeModal() {
			this.$emit("close")
		},

		generateNullParticipants() {
			const temporaryParticipant = []
			for (let index = 1; index <= 16; index++) {
				temporaryParticipant.push({
					id: null,
					is_deleted: 0,
					name: null,
					participant_id: null,
				})
			}
			return temporaryParticipant
		},

		// allowDrop(event, part) {
		// 	if (part.participantId == null) {
		// 		event.preventDefault()
		// 		return
		// 	}
		// },
		// drag(participantId, index, event) {
		// 	const participant = this.participant[index]
		// 	if (participant.is_played == 1 || participant.participant_id == null) {
		// 		event.preventDefault()
		// 		return
		// 	}

		// 	event.dataTransfer.setData("text/plain", participantId)
		// 	event.dataTransfer.setData("index", index)
		// },
		// drop(toIndex, event, arr) {
		// 	event.preventDefault()
		// 	const fromIndex = event.dataTransfer.getData("index")
		// 	if (arr.is_played == 1) {
		// 		this.showToast(
		// 			"warning",
		// 			"top-right",
		// 			"Cambiar posición",
		// 			"AlertCircleIcon",
		// 			"La posicion que quiere cambiar ya esta ocupado por un participante que ya jugo."
		// 		)
		// 		return
		// 	}
		// 	const oldObject = this.participant.find((x, index) => index == toIndex)
		// 	const newObject = this.participant.find((x, index) => index == fromIndex)
		// 	const oldGroup = oldObject.group
		// 	const oldAlignment = oldObject.alignment
		// 	const newGroup = newObject.group
		// 	const newAlignment = newObject.alignment
		// 	newObject.group = oldGroup
		// 	newObject.alignment = oldAlignment
		// 	oldObject.group = newGroup
		// 	oldObject.alignment = newAlignment
		// 	this.$set(this.participant, toIndex, newObject)
		// 	this.$set(this.participant, fromIndex, oldObject)
		// },
		async addParticipantnew() {
			if (!this.participantSelect) {
				this.showToast(
					"warning",
					"top-right",
					"Participante no seleccionado",
					"AlertCircleIcon",
					"Tiene que seleccionar un participante"
				)
				return
			}
			const participantFilter = this.participant.filter(
				(fil) => fil.participant_id !== null && fil.is_deleted == 0
			).length

			const finParticipant = this.participant.find(
				(find) => find.participant_id == this.participantSelect.id && find.is_deleted == 0
			)
			// if (finParticipant) {
			// 	this.showToast(
			// 		"warning",
			// 		"top-right",
			// 		"Participante duplicado",
			// 		"AlertCircleIcon",
			// 		`ya existe el participante ${finParticipant.name}`
			// 	)

			// 	console.log("particpante duplicacdo")
			// 	return
			// }
			if (participantFilter === 16) {
				this.showToast(
					"warning",
					"top-right",
					"Limite de participantes",
					"AlertCircleIcon",
					"Se superó el número de participantes."
				)
				console.log("exedio")

				return
			}

			const nullIndex = this.participant.findIndex((part) => part.participant_id === null)
			if (nullIndex !== -1) {
				// Actualizar el elemento existente en el arreglo
				this.$set(this.participant, nullIndex, {
					id: null,
					participant_id: this.participantSelect.id,
					name: this.participantSelect.name,
					is_deleted: 0,
				})
			}
		},
		deleteParticipant(partDelete) {
			if (partDelete.id && partDelete.in_use == 1) {
				this.showToast(
					"warning",
					"top-right",
					"Eliminar participante",
					"AlertCircleIcon",
					"El participante no se puede eliminar ya que se encuentra asociado"
				)
				return
			}

			if (partDelete.id !== null) {
				this.$set(partDelete, "is_deleted", 1)
			} else {
				partDelete
				const newParticpant = this.participant.filter(
					(part) => part.participant_id !== partDelete.participant_id || part.id !== null
				)
				this.participant = [...newParticpant]
			}

			this.participant.push({
				id: null,
				participant_id: null,
				name: null,
				is_deleted: 0,
			})
			console.log("parti: ", this.participant)
		},

		async fetchOptionsSpecimens(search, loading) {
			clearTimeout(this.debounce)
			this.debounce = setTimeout(async () => {
				loading(true)
				if (search.length > 1) {
					await this.getSelectParticipants(search)
				}
				loading(false)
			}, 300)
		},

		async getSelectParticipants(search) {
			this.isLoading = true
			const params = {
				search: search,
			}
			const { data } = await tournamentService.getParticipant(params)
			this.optionParticipant = data
			this.isLoading = false
		},

		async getByParticipants() {
			this.isPreloading()

			try {
				const { data } = await tournamentService.getbyDate(this.infoParticipant.idEvent)

				console.log(data)
				await Promise.all(
					data.slice(0, 16).map(async (item, index) => {
						const participantWithIndex = { ...item }
						const findIndex = this.participant.findIndex((item2, index2) => index2 == index)
						this.$set(this.participant, findIndex, participantWithIndex)
					})
				)
				this.isPreloading(false)
			} catch (error) {
				console.error("Error al obtener los datos:", error)
				this.isPreloading(false)
			}
		},

		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		async registerParticipant() {
			if (!this.participant.some((participant) => participant && participant.participant_id)) {
				this.showToast(
					"warning",
					"top-right",
					"Agrege un participante",
					"AlertCircleIcon",
					"Tiene que agregar un participante"
				)
				return
			}

			const { isConfirmed } = await this.showConfirmSwal({
				text: `Seguro de desea agregar participantes`,
			})
			if (!isConfirmed) return
			const data = {
				event_id: this.infoParticipant.idEvent,
				participants: this.participant.filter((part) => part.participant_id !== null),
			}
			try {
				this.isLoading = true
				await tournamentService.registerParticipantEvents(data)
				this.$emit("refresh")
				this.showToast(
					"success",
					"top-right",
					"Torneos",
					"InfoIcon",
					"Se agregó participantes de forma correcta"
				)
				this.$emit("refresh")
				this.isLoading = false
			} catch (error) {
				this.isLoading = false
			}
		},
		debounceInput: _.debounce(function (e) {
			this.name = e.target.value
		}, 500),
		async addParticipant() {
			if (this.name.length < 4) return
			let exists = this.participants.find((p) => p == this.name)
			if (exists) {
				this.showInfoSwal({
					title: "El participante ya fue ingresado",
					text: "Por favor ingrese otro",
				})
				return
			} else {
				const { ok, msg } = (await participantService.validatesSaveParticipant({ name: this.name, type_id: 1 }))
					.data
				if (!ok) {
					this.showInfoSwal({
						title: "El participante ya fue registrado",
						text: "Por favor ingrese otro",
					})
					return
				}
				this.participants.unshift(this.name)
				this.name = ""
			}
		},
		async saveParticipants() {
			this.isPreloading()
			if (this.participants.length === 0) {
				this.isPreloading(false)
				return
			}
			let count = 0
			for await (const parti of this.participants) {
				try {
					const { ok, msg } = (
						await participantService.saveParticipant({
							name: parti,
							id_user: this.currentUser.user_id,
							type_id: 1,
						})
					).data
					count++

					if (this.participants.length == count) {
						this.openModal = false
						this.participants = []
						await this.showSuccessToast("Los participantes fueron agregados con éxito")
					}
				} catch (error) {
					console.error(error)
					this.isPreloading(false)
				} finally {
					this.isPreloading(false)
				}
			}
		},
		deleteParticipantnew(index) {
			this.$delete(this.participants, index)
		},
	},
	watch: {
		valid() {
			if (this.name.length > 3) {
				this.valid = true
			} else {
				this.valid - false
			}
		},
	},
}
</script>
<style>
.disable {
	pointer-events: none;
}
.mxa {
	display: flex;
	justify-content: center;
	align-items: center;
}
.container-grid {
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
}
.group-container {
	color: #7367f0;
	border-bottom: 1px solid #ebe9f1;
}
.group-alignment {
	color: #7367f0;
	border-bottom: 1px solid #ebe9f1;
	border-left: 1px solid #ebe9f1;
	/* border-right: 1px solid #82868b; */
}
.group-participant {
	border-bottom: 1px solid #ebe9f1;
}

.text-participants {
	border-bottom: 1px solid #ebe9f1;
	border-right: 1px solid #ebe9f1;
}

.text-participant1 {
	border-right: 1px solid #ebe9f1;
	border-bottom: 1px solid #ebe9f1;
}
.text-participant2 {
	border-bottom: 1px solid #ebe9f1;
}
.containers {
	border-right: 1px solid #ebe9f1;
	border-top: 1px solid #ebe9f1;
	border-left: 1px solid #ebe9f1;
	margin-top: 0.5rem;
}

.bg-par {
	background-color: rgba(128, 149, 255, 0.1);
}

.color2 {
	background-color: rgba(128, 149, 255, 0.1);
}
.transparents {
	background-color: transparent;
	color: transparent;
}
.error-input {
	margin-bottom: 0;
	color: red;
	font-size: 12px;
	margin-top: 2px;
}
.margin-btn-add {
	margin-top: 1.5rem !important;
}
</style>
