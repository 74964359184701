<template>
	<b-modal
		hide-footer
		centered
		no-close-on-backdrop
		size="xlg"
		v-model="isActive"
		@hidden="handleHidden"
		@hide="handleHide"
		title-class="w-100 d-flex justify-content-between"
	>
		<template #modal-title>
			<div class="d-flex flex-column flex-md-row w-100 justify-content-between">
				<span class="d-inline h4 mb-25 mt-25">
					{{ info.tournament }} / {{ info.coliseum }} - {{ info.coliseum_city }}
				</span>
				<div class="d-flex align-items-center">
					<span class="mr-2">
						<feather-icon class="mr-05 text-primary" icon="FlagIcon" />
						Fecha:
						<span class="font-weight-bolder">{{ info.date | myGlobalDayShort }}</span>
					</span>
					<span class="text-right">
						<feather-icon class="mr-05 text-primary" icon="CalendarIcon" />
						Etapa:
						<span class="font-weight-bolder">{{ info.stage }}</span>
					</span>
				</div>
			</div>
		</template>
		<b-overlay :show="isLoading">
			<div class="accordion" role="tablist">
 				<template v-for="(front, i) in fronts">
					<b-card no-body class="mb-1 border-primary" :key="`front-${i}`" :class="bgFront(front)">
						<b-card-header header-tag="header" class="w-100 p-0 p-md-1" role="tab">
						<b-button
								block
								class="d-flex flex-column flex-md-row justify-content-between w-100"
								variant="flat-primary"
								@click="changeAccordion(i)"
							>
								<div class="d-flex align-items-center gap-x-2">
									<h4 class="d-inline mb-0">Frente #{{ i + 1 }}</h4>
								</div>
								<div class="d-flex align-items-center mt-1 mt-md-0" style="align-self: flex-end">
									<p class="mb-0 mr-1">
										Requeridos:
										<span class="font-weight-bolder">{{ front.specimens_required }}</span>
										/ Jugados:
										<span class="font-weight-bolder">{{ front.results.length }}</span>
									</p>
									<feather-icon :icon="front._open ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="20" />
								</div>
							</b-button>
						</b-card-header>
						<b-collapse accordion="my-accordion" role="tabpanel" v-model="front._open">
							<b-card-body>
								<validation-observer ref="form">
									<b-row
										class="mb-1"
										v-for="(result, indexResult) in front.results"
										:key="`results-${i}-${indexResult}`"
									>
										<b-col cols="12">
											<div class="d-flex justify-content-between">
												<div class="d-flex align-items-center mb-50">
													<div
														class="circle-front ml-1"
														:class="
															result.result_id == 1
																? 'circle-victoria'
																: result.result_id == 2
																? 'circle-empate'
																: result.result_id == 3
																? 'circle-derrota'
																: 'circle-gray'
														"
													></div>
													<h4 class="mb-0 ml-1">Pelea #{{ indexResult + 1 }}</h4>
												</div>
												<feather-icon
													v-if="front.status_id != 3"
													@click="deleteSpecimen(result, i, indexResult)"
													size="20"
													icon="XIcon"
													class="text-danger cursor-pointer"
												/>
											</div>
										</b-col>
										<b-col md="4" xl="3" class="mb-1 mb-md-0 d-flex flex-column align-items-start">
											<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
												<v-select
													:disabled="front.status_id == 3"
													:options="arraySpecimens"
													:reduce="(opt) => opt.id"
													label="plate"
													v-model="result.specimen_id"
													class="border-primary rounded bg-white"
													placeholder="Seleccionar ejemplar"
													:class="errors[0] ? 'border-danger' : ''"
													@input="($event) => selectSpecimen($event, i, indexResult)"
													:selectable="
														(option) => !option.disabled && !option.nursing_status_id
													"
												>
													<template #option="{ alias, plate, nursing_status_id }">
														<div class="selected d-center d-flex align-items-center">
															<p class="mb-0">{{ alias }} ({{ plate }})</p>
															<v-icon
																v-if="nursing_status_id"
																name="md-healthandsafety-outlined"
																scale="1.2"
																class="ml-1 p-0"
																:class="
																	nursing_status_id == 1
																		? 'text-info'
																		: 'text-success'
																"
															/>
														</div>
													</template>

													<template #selected-option="{ alias, plate, nursing_status_id }">
														<div class="selected d-center d-flex align-items-center">
															<p class="mb-0">{{ alias }} ({{ plate }})</p>
															<v-icon
																v-if="nursing_status_id"
																name="md-healthandsafety-outlined"
																scale="1.2"
																class="ml-1 p-0"
																:class="
																	nursing_status_id == 1
																		? 'text-info'
																		: 'text-success'
																"
															/>
														</div>
													</template>
												</v-select>

												<small class="text-danger">{{ errors[0] }}</small>
											</ValidationProvider>
											<SpecimenPlate
												v-if="
													result.specimen_id &&
													arraySpecimens.find((it) => it.id == result.specimen_id)
												"
												:specimen="{
													id: arraySpecimens.find((it) => it.id == result.specimen_id).id,
													plate: arraySpecimens.find((it) => it.id == result.specimen_id)
														.plate,
													alias: arraySpecimens.find((it) => it.id == result.specimen_id)
														.alias,
													thumb: arraySpecimens.find((it) => it.id == result.specimen_id)
														.thumb,
													image: arraySpecimens.find((it) => it.id == result.specimen_id)
														.image,
												}"
												class="text-left mb-25 mt-1"
											/>
										</b-col>
										<b-col md="4" lg="3" class="mb-1 mb-md-0 position-relative">
											<div class="d-flex justify-content-end container-add-rival">
												<AddParticipant
													:tournament="true"
													@refresh="initRivals"
													v-b-tooltip.hover
													title="Agregar rival"
													class=""
												/>
											</div>
											<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
												<v-select
													:disabled="front.status_id == 3"
													:options="arrayRivals"
													:reduce="(opt) => opt.id"
													label="name"
													v-model="result.rival_id"
													class="border-primary rounded bg-white"
													placeholder="Seleccionar rival"
													:class="errors[0] ? 'border-danger' : ''"
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</ValidationProvider>
										</b-col>
										<b-col md="4" lg="2" class="mb-1 mb-lg-0">
											<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
												<v-select
													:options="arrayResults"
													:reduce="(opt) => opt.id"
													label="name"
													v-model="result.result_id"
													class="border-primary rounded bg-white"
													placeholder="Seleccionar resultado"
													:class="errors[0] ? 'border-danger' : ''"
													:disabled="front.status_id == 3"
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</ValidationProvider>

 											<!-- new -->
											<div class="pt-1">
												<small style="padding-left: 0.2rem" v-if="result.result_id == 3">
													Corrido: {{ result.behavior ? "SI" : "NO" }}
												</small>
												<b-form-checkbox
													v-if="result.result_id == 3"
													v-model="result.behavior"
													class="d-inline ml-1"
													switch
													:disabled="front.status_id == 3"
												/>
											</div>

											<!-- new -->
										</b-col>

										<b-col md="4" xl="2" class="mb-1 mb-md-0 text-center">
											<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
												<v-select
													:disabled="front.status_id == 3 || result.specimen_id == null"
													:options="arraystatusspecimen"
													:reduce="(opt) => opt.id"
													label="name"
													v-model="result.status_specimen"
													class="border-primary rounded bg-white"
													placeholder="Seleccionar estado"
													:class="errors[0] ? 'border-danger' : ''"
													@input="addNursing(result.status_specimen, i, indexResult)"
												/>
												<small class="text-danger">{{ errors[0] }}</small>
												<small
													v-if="
														result.is_nursing && result.reasons && result.reasons.length > 0
													"
													class="text-center text-primary font-weight-bold"
												>
													Se enviara a enfermeria
												</small>
											</ValidationProvider>
										</b-col>
										<b-col md="4" xl="2" class="text-center">
											<div
												v-if="result.video && result.video.uid && !result.video.oldUid"
												class="container-image"
											>
												<div
													class="cursor-pointer d-flex align-items-center select-video"
													style="position: relative"
												>
													<div @click="ValidStatusVideo(result, i, indexResult)">
														<b-avatar
															:src="result.video.status == 2 ? result.video.thumb : null"
															rounded
															size="80"
															icon="film"
															badge
															badge-variant="danger"
															v-if="result.video.status == 2"
														>
															<template #badge>
																{{
																	result.video.duration ? result.video.duration : "-"
																}}
															</template>
															<!-- v-if="result.video.status == 2 && front.status_id != 3" -->
														</b-avatar>
													</div>

													<div
														v-if="result.video.status == 2 && front.status_id != 3"
														style="width: 24px; height: 24px"
														class=""
													>
														<feather-icon
															@click="deleteVideo(i, indexResult, result.video.uid)"
															size="20"
															icon="TrashIcon"
															class="text-danger cursor-pointer ml-50"
														/>
													</div>
													<div v-if="result.video.status == 1">
														<b-avatar
															:src="null"
															rounded
															size="80"
															icon="film"
															badge
															badge-variant="danger"
														>
															<template #badge>
																{{ "-" }}
															</template>
															<!-- v-if="result.video.status == 2 && front.status_id != 3" -->
														</b-avatar>

														<feather-icon
															size="20"
															:icon="
																result.video.status == 1
																	? 'RefreshCcwIcon'
																	: 'CheckIcon'
															"
															class="cursor-pointer ml-1 text-success"
															@click="getFronts"
														/>
													</div>
												</div>
											</div>
											<div v-else-if="!(result.video && result.video.uid) || result.video.oldUid">
												<template v-if="result.video && result.video.status == 1">
													<b-avatar
														:src="null"
														rounded
														size="80"
														icon="film"
														badge
														badge-variant="danger"
													>
														<template #badge>
															{{ "-" }}
														</template>
														<!-- v-if="result.video.status == 2 && front.status_id != 3" -->
													</b-avatar>
												</template>

												<template v-else>
													<b-form-file
														v-model="result.video.file"
														class="border-primary rounded text-left"
														placeholder="Seleccionar video"
														@input="uploadVideoItem(result.video.file, i, indexResult)"
														:disabled="
															!(
																result.specimen_id &&
																result.rival_id &&
																result.result_id &&
																result.status_specimen
															)
														"
														accept="video/*"
													/>
													<p
														class="mb-0 font-small-1 text-center"
														v-if="front.status_id == 3 && !result.video.file"
													>
														Seleccione un video para registrarlo.
													</p>
													<p
														class="mb-0 text-danger font-small-1 text-left"
														v-if="
															!(
																result.specimen_id &&
																result.rival_id &&
																result.result_id &&
																result.status_specimen
															)
														"
													>
														Se deben completar todos los campos
													</p>

													<!-- <p>{{ result.video ? result.video.upload : "SD" }}</p> -->
													<div class="bar-loading mt-1" v-if="result.video.file">
														<div
															class="bar"
															:style="{ width: `${result.video.upload}%` }"
															:class="
																result.video.upload != 100 ? 'bg-primary' : 'bg-success'
															"
														></div>
														<p
															class="text font-weight-bolder"
															:style="{
																color: result.video.upload > 50 ? '#fff' : '#7367f0',
															}"
														>
															{{
																result.video.upload != 100
																	? `Subiendo ${result.video.upload}%`
																	: "Subido"
															}}
														</p>
													</div>
												</template>
											</div>
										</b-col>
										<b-col
											cols="12"
											v-if="result.is_nursing && result.reasons && result.reasons.length > 0"
										>
											<b-alert variant="warning" class="p-1 mt-1" show>
												Es necesario
												<b>"GUARDAR o FINALIZAR"</b>
												el frente para llevar al ejemplar a enfermeria
											</b-alert>
										</b-col>
									</b-row>
									<b-row v-if="videoUpload && videoUploadFront == i && front.status_id != 3">
										<b-col cols="12" class="">
											<!-- {{ result.video.upload }} -->
											<b-alert variant="warning" class="p-1" show>
												Es necesario
												<b>"GUARDAR o FINALIZAR"</b>
												el frente para completar la subida del video
											</b-alert>
										</b-col>
									</b-row>
								</validation-observer>

								<b-row class="mb-2"></b-row>

								<b-row v-if="tab != 2">
									<template>
										<b-col md="6">
											<div class="d-flex flex-column align-items-start">
												<template v-if="front.status_id != 3">
													<div class="d-flex w-100 justify-content-start">
														<b-button
															class="btn-footer"
															v-if="
																fronts[i].results.length < fronts[i].specimens_required
															"
															@click="addResult(i)"
															variant="primary"
														>
															<feather-icon class="mr-05" icon="PlusIcon" />
															Agregar pelea
														</b-button>
													</div>
												</template>
												<div class="d-flex mt-1">
													<div v-if="info.stage != 'FINAL'">
														<span>¿Se clasificó a la siguiente etapa?</span>

														<b-form-checkbox
															:disabled="front.status_id == 3"
															v-model="front.classify"
															class="d-inline ml-1"
															switch
														/>
													</div>
													<div v-else class="d-flex align-items-center">
														<span>¿Que puesto quedo?</span>
														<div>
															<b-form-select
																:options="
																	rankingOpts.filter((it) =>
																		info.counter_awards ? it.value : true
																	)
																"
																v-model="front.ranking"
																class="ml-1"
																:disabled="front.status_id == 3"
															/>
														</div>
													</div>

													<b-button
														:disabled="!front.ranking"
														style="position: relative"
														class="ml-2 d-flex align-items-center"
														variant="outline-primary"
														size="sm"
														@click="
															;(awards.show = !awards.show),
																(awards.info = { ...front, _idx: i })
														"
														v-if="info.stage_id == 4"
													>
														<feather-icon icon="AwardIcon" class="mr-50" />
														<span class="align-middle">Premios</span>

														<p class="mb-0 ml-25">
															{{
																front.awards && front.awards.counter
																	? `(${front.awards && front.awards.counter})`
																	: ""
															}}
														</p>
													</b-button>
												</div>
											</div>
										</b-col>
										<b-col
											md="6"
											class="d-flex flex-column flex-md-row justify-content-end align-items-end gap-x-2"
											v-if="front.status_id != 3"
										>
											<b-button
												:disabled="front.results.length == 0 || front.uploadVideoItem"
												variant="primary"
												@click="saveResults(i, 1)"
												class="btn-footer mt-1 mt-md-0"
											>
												Guardar
											</b-button>
											<b-button
												:disabled="front.results.filter((it) => it.confrontation_id).length > 0"
												variant="danger"
												@click="cancelFront(front)"
												class="btn-footer mt-1 mt-md-0"
											>
												Cancelar
											</b-button>
											<b-button
												:disabled="front.results.length <= 0 || front.uploadVideoItem"
												variant="success"
												@click="saveResults(i, 3)"
												class="btn-footer mt-1 mt-md-0"
											>
												Finalizar
											</b-button>
										</b-col>
									</template>
									<b-col class="d-flex justify-content-end" v-if="front.status_id == 3">
										<div>
											<p class="mb-0 text-right font-weight-bolder">Frente finalizado.</p>
											<b-button
												v-if="front.buttonSaveActive"
												variant="success"
												@click="saveVideoEventFinalizated(i)"
												class="btn-footer mt-1"
											>
												Guardar video(s)
											</b-button>
										</div>
									</b-col>
								</b-row>
								<div class="ml-0 mt-1 mb-1">
									<h5 class="mb-0">Enfermería</h5>

									<div class="d-flex align-items-center justify-content-start mt-50">
										<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-danger" />
										<small class="mb-0 ml-25">Enviar ejemplar a enfermeria</small>
									</div>

									<div class="d-flex align-items-center justify-content-start mt-50">
										<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
										<small class="mb-0 ml-25">Ejemplar en enfermería en espera</small>
									</div>

									<div class="d-flex align-items-center justify-content-start mt-50">
										<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
										<small class="mb-0 ml-25">Ejemplar en enfermería en proceso</small>
									</div>
								</div>
							</b-card-body>
						</b-collapse>
					</b-card>
				</template>
			</div>
		</b-overlay>
		<b-modal centered v-model="modalSpecimenSelect.show" :title="modalSpecimenSelect.title" hide-footer>
			<div class="container-specimen">
				<div
					class="d-flex flex-column align-items-center cursor-pointer pt-1 pb-1"
					v-for="(spe, indexFilter) in arraySpecimensFilter"
					:key="indexFilter"
					@click="!(spe.status_tournament_event_id == 1 || spe.select) && selectSpecimenByCard(spe)"
					:class="spe.status_tournament_event_id == 1 || spe.select ? 'bg-select-specimen' : ''"
				>
					<SpecimenPlate
						:specimen="{
							id: spe.id,
							plate: spe.plate,
							alias: spe.alias,
							thumb: spe.thumb,
						}"
						class="mb-25"
						style="pointer-events: none"
					/>
					<small class="text-primary font-weight-bold">{{ spe.status_tournament_event }}</small>
				</div>
			</div>
		</b-modal>
		<AwardsModal
			:show="awards.show"
			:info="awards.info"
			@closing="awards.show = false"
			@refresh="$emit('refresh')"
		/>
		<AddSpecimensNurseryVue
			:show="addNursery.show"
			:info="addNursery.info"
			@closing="addNursery.show = false"
			@set-treatments="setNursingReasons"
		/>
	</b-modal>
</template>

<script>
import { mapActions } from "vuex"

import tournamentService from "@/services/tournament/default.service"
import videosService from "@/services/videos.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import AwardsModal from "./AwardsModal.vue"
import AddSpecimensNurseryVue from "@/views/amg/nursery/components/AddSpecimensNursery.vue"
import AddParticipant from "@/views/brain/tournament/views/participants/components/AddParticipant.vue"

export default {
	components: { SpecimenPlate, AwardsModal, AddSpecimensNurseryVue, AddParticipant },
	props: {
		show: Boolean,
		info: Object,
		tab: Number,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		search: "",
		fronts: [],
		arrayResults: [
			{
				id: 1,
				name: "TRIUNFO",
			},
			{
				id: 2,
				name: "EMPATE",
			},
			{
				id: 3,
				name: "DERROTA",
			},
		],

		arrayBehavior: [
			{
				id: 1,
				name: "NO",
			},
			{
				id: 2,
				name: "SI",
			},
		],
		arraystatusspecimen: [
			{
				id: 1,
				name: "LIMPIO (ACTIVO)",
			},
			{
				id: 2,
				name: "HERIDO",
			},
			{
				id: 3,
				name: "MUERTO",
			},
		],
		arrayRivals: [],
		arraySpecimens: [],
		arraySpecimensFilter: [],
		modalSpecimenSelect: {
			show: false,
			title: "Modal",
			front_index: null,
			result_index: null,
		},
		activeAccorden: 0,
		progressTotal: 0,
		videoCounterUpload: 0,
		videoCounterUploadCheck: 0,
		awards: { show: false, info: {} },
		rankingOpts: [
			{ value: 9, text: "N/A" },
			{ value: 1, text: "Primero" },
			{ value: 2, text: "Segundo" },
			{ value: 3, text: "Tercero" },
			{ value: 4, text: "Cuarto" },
			{ value: 5, text: "Quinto" },
			{ value: 6, text: "Sexto" },
			{ value: 7, text: "Septimo" },
			{ value: 8, text: "Octavo" },
		],
		videoUpload: false,
		addNursery: { show: false, info: {} },
		readyToSave: [],
	}),
	methods: {
		...mapActions("header_book", ["toggleModal", "setIndexImage", "setTextImage", "setImages"]),
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.videoUpload = false
			this.videoUploadFront = null
			this.FileSelectedEvtFin = null
		},
		async getFronts() {
			this.isLoading = true
			const { data } = await tournamentService.getEventFronts({ event_id: this.info.id })
			this.fronts = data.data.map((front) => {
				const awards = front.awards
					? front.awards.items.map((it) => ({
							...it,
							value: it.type_id == 1 ? parseFloat(it.value) : it.value,
					  }))
					: []
				return {
					...front,
					_open: false,
					ranking: front.awards ? front.awards.position_id : null,
					results:
						front?.results?.map((resu) => {
							return {
								...resu,
								behavior: resu.behavior == 1  || resu.behavior == null ? false : true,
							}
						}) || [],
					classify: front.classify == 1,
					awards: front.awards
						? {
								...front.awards,
								items: awards,
						  }
						: null,
					uploadVideoItem: false,
				}
			})
			this.fronts[this.activeAccorden]._open = true
			this.isLoading = false
			this.initPositions()
		},
		changeAccordion(idx) {
			// this.activeAccorden = idx;
			this.fronts.forEach((front, i) => {
				if (idx == i) {
					front._open = !front._open
				} else {
					front._open = false
				}
			})
			this.reloadSpecimens()
		},
		addResult(index) {
			this.fronts[index].results.push({
				specimen_id: null,
				rival_id: null,
				result_id: null,
				video: {
					file: null,
					oldUid: null,
					upload: 0,
				},
				status_specimen: null,
				plate_specimen: null,
				behavior: false,
			})
		},
		async initRivals() {
			const { data } = await tournamentService.getRivals()
			this.arrayRivals = data
		},
		async initSpecimens() {
			const { data } = await tournamentService.getSpecimensToEvent({ id: this.info.id, search: null })
			this.arraySpecimens = data
			this.reloadSpecimens()
		},
		async saveResults(i, status) {
			if (!(await this.$refs.form[i].validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}
			if (status == 3) {
				const { isConfirmed } = await this.showConfirmSwal()
				if (!isConfirmed) return
			}

			this.isLoading = true

			try {
				const resp = await tournamentService.saveResults({
					body: {
						results: this.fronts[i].results.map((it) => ({
							...it,
							oldUid: null,
							behavior: it.behavior ? 2 : 1,
						})),
						tournament_event_id: this.fronts[i].tournament_event_id,
						classify: this.fronts[i].classify ? 1 : 2,
						status_id: status,
						front_id: this.fronts[i].front_id,
						raking: this.fronts[i].ranking,
					},
				})
				status == 3 && this.$emit("refresh")
				await this.initSpecimens()
				this.activeAccorden = i
				this.progressTotal = 0
				this.videoCounterUpload = 0
				this.videoCounterUploadCheck = 0
				this.videoAdded = false
				this.isLoading = false
				this.videoUpload = false
				await this.getFronts()
				this.isPreloading(false)
			} catch (e) {
				this.isLoading = false
				this.isPreloading(false)
			}
		},
		selectSpecimen(e, index, indexResult) {
			const specimen = this.arraySpecimens.find((it) => it.id == e)
			if (specimen && specimen.status_tournament_event_id == 1) {
				this.fronts[index].results[indexResult].specimen_id = null
				this.showToast("warning", "top-right", "Resultados", "WarningIcon", "El ejemplar ya fue calificado.")
				return
			}
			const existSpecimen = this.fronts[index].results
				.filter((it, ind) => ind != indexResult)
				.some((it) => it.specimen_id == e)
			if (existSpecimen) {
				this.fronts[index].results[indexResult].specimen_id = null
				this.showToast("warning", "top-right", "Resultados", "WarningIcon", "Ya se eligió el ejemplar.")
			}
			this.reloadSpecimens()
		},
		async cancelFront(front) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			if (this.fronts.length == 1) {
				this.showToast(
					"warning",
					"top-right",
					"Resultados",
					"WarningIcon",
					"No puede eliminar todos los frentes, en tal caso podría cancelar la fecha."
				)
				return
			}
			this.isPreloading()
			await tournamentService.cancelFront({ id: front.front_id })
			await this.getFronts()
			this.$emit("refresh")
			this.isPreloading(false)
		},
		showTopeVideo(row) {
			const rival = this.arrayRivals.find((it) => it.id == row.rival_id)
			const specimen = this.arraySpecimens.find((it) => it.id == row.specimen_id)
			this.setImages([
				{
					...row.video,
					type: "video",
					url: row.video.uid,
					text: `${specimen.alias ? specimen.alias : ""} (${specimen.plate}) VS ${rival.name}`,
					specimen: { id: row.specimen_id },
				},
			])
			// this.setTextImage({
			// 	title: `${specimen.alias ? specimen.alias : ""} (${specimen.plate}) VS ${rival.name}`,
			// 	url: `/ejemplares/detalle/${specimen.id}`,
			// })
			this.setIndexImage(0)
			this.toggleModal()
		},
		deleteVideo(i, index, uid) {
			this.fronts[i].results[index].video.oldUid = uid
		},
		async ValidStatusVideo(row, i, indexResult) {
			const { data: valid } = await videosService.validStatusVideo({
				id: this.fronts[i].results[indexResult].video.uid,
			})
			if (valid.valid) {
				this.showTopeVideo(row)
			} else {
				this.showToast("warning", "top-right", "Resultados", "WarningIcon", "El video no esta listo todavia")
			}
		},
		async deleteSpecimen(result, i, indexResult) {
			if (!result.confrontation_id && !result.front_id && !result.tefs_id) {
				this.fronts[i].results = this.fronts[i].results.filter((ir, index) => index != indexResult)
				await this.initSpecimens()
				return
			}
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isPreloading()
			await tournamentService.deleteEventFrontSpecimen({
				confrontation_id: result.confrontation_id,
				front_id: result.front_id,
				tefs_id: result.tefs_id,
			})
			await this.getFronts()
			await this.initSpecimens()
			// this.reloadSpecimens();
			this.isPreloading(false)
		},
		async selectSpecimenByPlate(i, indexResult, plate) {
			const { data } = await tournamentService.getSpecimensToEvent({ id: this.info.id, search: plate })
			let arraySpecimensResultsSelects = []
			for (let index = 0; index < this.fronts.length; index++) {
				const element = this.fronts[index]
				arraySpecimensResultsSelects = [...arraySpecimensResultsSelects, ...element.results]
			}
			const arrSpecimens = arraySpecimensResultsSelects.filter((it) => it.specimen_id)
			this.arraySpecimensFilter = data.map((it) => {
				const existSpecimen = arrSpecimens.some((exist) => exist.specimen_id == it.id)
				return {
					...it,
					select: existSpecimen,
				}
			})

			if (this.arraySpecimensFilter.length == 1) {
				this.fronts[i].results[indexResult].specimen_id = this.arraySpecimensFilter[0].id
				// (modalSpecimenSelect.show = false);
				this.fronts[i].results[indexResult].plate_specimen = this.arraySpecimensFilter[0].plate
				this.showToast("success", "top-right", "Torneos", "SuccessIcon", "Ejemplar seleccionado")
				return
			}

			this.modalSpecimenSelect.show = true
			this.modalSpecimenSelect.front_index = i
			this.modalSpecimenSelect.result_index = indexResult
		},
		validPlateSpecimenExist(i, indexResult, plate) {},
		clearIdSpecimen(i, indexResult) {
			this.fronts[i].results[indexResult].specimen_id = null
			this.fronts[i].results[indexResult].plate_specimen = null
		},
		selectSpecimenByCard(spe) {
			this.fronts[this.modalSpecimenSelect.front_index].results[
				this.modalSpecimenSelect.result_index
			].specimen_id = spe.id
			this.modalSpecimenSelect.show = false
			this.fronts[this.modalSpecimenSelect.front_index].results[
				this.modalSpecimenSelect.result_index
			].plate_specimen = this.arraySpecimens.find((it) => it.id == spe.id).plate
			this.showToast("success", "top-right", "Torneos", "SuccessIcon", "Ejemplar seleccionado")
		},
		reloadSpecimens() {
			let arraySpecimensResultsSelects = []
			for (let index = 0; index < this.fronts.length; index++) {
				const element = this.fronts[index]
				arraySpecimensResultsSelects = [...arraySpecimensResultsSelects, ...element.results]
			}
			for (let index = 0; index < this.arraySpecimens.length; index++) {
				this.arraySpecimens[index].disabled = false
				// this.arraySpecimens[index].disabled
				arraySpecimensResultsSelects.forEach((it) => {
					if (it.specimen_id == this.arraySpecimens[index].id) {
						this.arraySpecimens[index].disabled = true
					}
				})
			}
			this.arraySpecimens = this.arraySpecimens.sort((a, b) => a.disabled - b.disabled)
		},
		configReload() {
			this.videoCounterUploadCheck++
		},
		async initPositions() {
			const { data } = await tournamentService.getEventAwards({ event_id: this.info.id, all: 1 })

			for (let index = 0; index < this.fronts.length; index++) {
				const element = this.fronts[index]
				!this.fronts[index].ranking ? (this.fronts[index].ranking = data[index].num) : null
				//  ? this.fronts[index].awards.counter = data[index].awards.length : null
				if (!this.fronts[index].awards) {
					this.fronts[index].awards = {
						counter: data[index].awards ? data[index].awards.length : null,
					}
				}
			}
		},
		bgFront(front) {
			if (this.info.stage_id == 4) {
				return [null, 9].includes(front.ranking)
					? "bgLightDefault"
					: front.ranking == 1
					? "bgLightFinished"
					: "bgLightPrimary"
			} else {
				return front.classify ? "bgLightFinished" : "bgLightDefault"
			}
		},
		async uploadVideoItem(file, i, indexResult) {
			// this.videoUploads.push({
			// 	front: i,
			// 	result: indexResult,
			// 	video: file,
			// 	upload: 0,
			// });

			// return
			if (this.fronts[i].status == "FINALIZADO") {
				this.fronts[i].buttonSaveActive = true
			}
			if (!file) return

			this.fronts[i].results[indexResult].video.upload = 0

			// this.fronts[i].uploadVideoItem = true
			this.videoUpload = true
			this.videoUploadFront = i
			this.isPreloading()

			let videoCounterUpload = 0
			let videoCounterUploadCheck = 0
			let lengthVideo = 0
			let offset = 0
			let chunkSize = 10000000
			if (file) {
				while (offset < file.size) {
					offset += chunkSize
					lengthVideo++
				}
				videoCounterUpload = videoCounterUpload + lengthVideo
			}

			const fn = () => {
				videoCounterUploadCheck++
				this.fronts[i].results[indexResult].video.upload = (
					(videoCounterUploadCheck / videoCounterUpload) *
					100
				).toFixed(2)
				if (this.fronts[i].results[indexResult].video.upload == 100) {
					// const someUpload = this.fronts.filter((it) => it.uploadVideoItem).length
					this.isPreloading(false)
					// this.saveResults(i, 1)
					// this.videoUpload = false;
				}
			}

			const response = await this.uploadVideoLaravel(file, fn)

			if (response.statusResponse == 500) {
				this.showToast(
					"danger",
					"top-right",
					"ERROR AL SUBIR VIDEO",
					"AlertCircleIcon",
					"ALGO SALIO MAL, VUELVA A SELECCIONAR EL VIDEO POR FAVOR"
				)

				this.fronts[i].results[indexResult].video.path = null
				this.fronts[i].results[indexResult].video.name = null
				this.fronts[i].results[indexResult].video.upload = 0
				this.fronts[i].results[indexResult].video.file = null
				this.isPreloading(false)

				return
			} else {
				this.isPreloading(false)
			}

			this.fronts[i].results[indexResult].video.path = response.url
			this.fronts[i].results[indexResult].video.name = response.fileName
			if (this.fronts[i].status == "FINALIZADO") {
				let doubleVideoValidation = this.readyToSave.findIndex(
					(index) => index.confrontation_id == this.fronts[i].results[indexResult].confrontation_id
				)
				if (doubleVideoValidation != -1) {
					this.readyToSave[doubleVideoValidation] = {
						confrontation: i,
						path_s3: response.url,
						name: response.fileName,
						confrontation_id: this.fronts[i].results[indexResult].confrontation_id,
					}
					return
				} else {
					this.readyToSave.push({
						confrontation: i,
						path_s3: response.url,
						name: response.fileName,
						confrontation_id: this.fronts[i].results[indexResult].confrontation_id,
					})
				}
			}
		},
		hasVideoUploaded() {
			// this.fronts[0].results[0].video.upload
			// null || 100
			let isUploading = this.fronts.some((f) => f.results.some((r) => r.video.upload == "100.00"))
			return isUploading
		},
		async handleHide(e) {
			if (this.closeConfirmed) {
				return
			} else {
				// cuando no hay cambios
				if (!this.hasVideoUploaded()) {
					return
				}
				e.preventDefault()
				const { isConfirmed } = await this.showConfirmSwal({
					title: "Actualmente hay videos que se estan subiendo. ",
					text: "¿Deseas salir de todas maneras?",
				})
				if (isConfirmed) {
					this.closeConfirmed = true
					this.handleHidden()
					this.$emit("closing")
				}
			}
		},
		async addNursing(status_specimen, i, indexResult) {
			if ([1, 2].includes(status_specimen)) {
				const specimen = this.arraySpecimens.find(
					(it) => it.id == this.fronts[i].results[indexResult].specimen_id
				)

				if (specimen.status_id == 6) {
					return
				}

				const { isConfirmed } = await this.showConfirmSwal({
					html: `
						<p class="mb-0">Desea enviar al ejemplar <span class="font-weight-bold">${
							specimen.alias || specimen.plate
						}</span> a enfermeria?</p>
					`,
					confirmButtonText: "Si",
					cancelButtonText: "No",
				})
				if (!isConfirmed) return
				this.fronts[i].results[indexResult].is_nursing = true
				this.addNursery.info = { specimen, _isTournamentResults: true, frontIdx: i, resultIdx: indexResult }
				this.addNursery.show = true
			} else {
				this.fronts[i].results[indexResult].is_nursing = false
			}
		},
		existSpecimenNursing(id) {
			return this.arraySpecimens.find((it) => it.id == id)
				? this.arraySpecimens.find((it) => it.id == id).nursing_status_id
				: null
		},
		setNursingReasons(e) {
			const result = this.fronts[this.addNursery.info.frontIdx].results[this.addNursery.info.resultIdx]
			result.reasons = e.reasons
		},
		async saveVideoEventFinalizated(indexConfrontation) {
			let videos = this.readyToSave.filter((index) => index.confrontation == indexConfrontation)
			let videosToDelete = videos.map(function (obj) {
				// Crear una copia del objeto sin la propiedad "observations"
				var newObj = { ...obj }
				delete newObj.confrontation
				return newObj
			})
			this.isPreloading()
			const response = await tournamentService.uploadVideoConfrontation(videosToDelete)
			this.videoUpload = false
			await this.getFronts()
			this.isPreloading(false)
			this.showToast(
				"success",
				"top-right",
				"Torneos",
				"AlertCircleIcon",
				"Se han actualizado los videos exitosamente"
			)
			this.readyToSave = this.readyToSave.filter((elemento) => !videos.includes(elemento))
		},
	},
	watch: {
		async show(val) {
			if (val) {
				await this.initSpecimens()
				// this.reloadSpecimens();
				this.initRivals()
				this.getFronts()
				this.isActive = val
				this.progressTotal = 0
				this.videoCounterUpload = 0
				this.videoCounterUploadCheck = 0
				this.closeConfirmed = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.circle-front {
	border: 3px solid var(--primary);
	height: 20px;
	width: 20px;
	border-radius: 50%;
}

.circle-front-active {
	background: var(--success);
	border-color: var(--success);
}

.circle-empate {
	background: var(--blue);
	border-color: var(--blue);
}

.circle-derrota {
	background: var(--danger);
	border-color: var(--danger);
}

.circle-gray {
	background: #f5f5f5;
	border-color: #f5f5f5;
}

.circle-victoria {
	background: var(--success);
	border-color: var(--success);
}

.bgLightDefault {
	// background-color: rgba($color: #cecece, $alpha: 0.2);
	background-color: rgba($color: #f06c67, $alpha: 0.2);
}

.bgLightPrimary {
	background-color: rgba($color: #ffe552, $alpha: 0.2);
}

.bgLightFinished {
	background-color: rgba($color: #28c76f, $alpha: 0.2);
}

.container-image {
	// border: 2px solid #ced0d1;
	overflow: hidden !important;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	// padding: 0 0.5rem;
	height: 100px;

	// background: #fff;
	.reload {
		animation: rotation 1s infinite linear;
	}

	.select-video {
		transition: 0.3s all ease-in-out;

		&:hover {
			color: #7367f0;
		}
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.btn-footer {
	width: 100%;

	@media screen and (min-width: 700px) {
		width: auto;
	}
}

.container-specimen {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;

	.bg-select-specimen {
		background: #f5f5f591;
	}
}

.progress {
	width: 100%;
	height: 12px;
	background: #f2f2f2;
	position: relative;
	margin-bottom: 1rem;
	transition: 0.4s all ease-in-out;

	.bar {
		position: absolute;
		top: 0;
		left: 0;
		height: 12px;
		transition: 0.4s all ease-in-out;
		// width: 50%;
		background: #7367f0;
	}
}

.thumb-image {
	height: 40px;
	width: 40px;
	object-fit: cover;
}

.badge-btn {
	height: 24px;
	width: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -12px;
	right: -12px;
	color: #fff;
}

.container-icon {
	position: absolute;
	top: -5px;
	right: -1.5px;
}

.bar-loading {
	background: #f5f5f5;
	width: 100%;
	height: 20px;
	border-radius: 4px;
	position: relative;
	overflow: hidden;

	.bar {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		// background: var(--primary);
		width: 0;
		transition: 0.4s all ease-in-out;
	}

	.text {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		bottom: 0;
		background: transparent;
		color: var(--primary);
		text-align: center;
		width: 100%;
		font-size: 12px;
		margin: 0;
	}
}

.container-add-rival {
	position: absolute;
	top: -24px;
	right: 14px;
	width: 100%;
	// background: red;
}
</style>

<style>
.b-avatar-badge.badge-danger {
	font-size: calc(12px) !important;
}
</style>
