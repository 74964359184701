<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.0"
		:width="width"
		:height="height"
		viewBox="160 0 610.000000 512.000000"
		preserveAspectRatio="xMidYMid meet"
		class=""
	>
		<g
			transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
			:fill="`${resolveVariant}`"
			stroke="none"
		>
			<path
				d="M6205 5114 c-168 -23 -310 -66 -447 -135 -162 -81 -306 -183 -598 -425 -371 -307 -485 -388 -674 -482 -158 -78 -362 -132 -497 -132 -54 0 -57 2 -69 30 -7 17 -22 37 -34 45 -25 18 -75 19 -98 1 -23 -17 -40 -64 -32 -90 4 -11 389 -862 856 -1891 575 -1266 857 -1877 874 -1892 28 -26 54 -29 87 -11 31 16 46 39 47 69 0 14 -214 497 -476 1073 -261 576 -474 1049 -472 1051 2 2 50 8 107 14 174 19 290 54 456 138 176 89 260 149 620 443 278 227 369 296 480 367 228 144 421 210 654 222 96 5 126 9 134 22 14 22 -678 1545 -713 1568 -24 16 -138 24 -205 15z"
			/>
			<path
				d="M4050 4956 c-268 -112 -465 -287 -666 -591 -62 -93 -100 -156 -275 -460 -185 -323 -269 -442 -415 -592 -120 -122 -253 -215 -392 -274 l-53 -22 -30 29 c-59 56 -149 21 -149 -59 0 -29 133 -165 1467 -1499 807 -807 1479 -1472 1495 -1479 60 -25 129 39 107 100 -6 14 -377 394 -827 843 -449 449 -814 819 -812 821 3 2 38 18 79 35 217 94 455 320 630 599 l32 52 -321 708 c-351 771 -349 766 -314 863 58 160 251 211 392 103 31 -23 40 -25 94 -19 74 7 241 62 340 111 76 38 298 182 298 194 0 3 -123 129 -273 279 -310 310 -300 303 -407 258z"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		variant: {
			type: String,
			required: false,
			default: "primary",
		},
		height: {
			type: Number,
			required: false,
			default: 23,
		},
		width: {
			type: Number,
			required: false,
			default: 23,
		},
	},
	computed: {
		resolveVariant() {
			let p_color = "#82868b"

			switch (this.variant) {
				case "success":
					p_color = "#28c76f"
					break
				case "primary":
					p_color = "#7367f0"
					break
				case "info":
					p_color = "#00b8cf"
					break
				case "warning":
					p_color = "#ff9f43"
					break
				case "danger":
					p_color = "#ea5455"
					break
				default:
					p_color = "#82868b"
					break
			}

			return p_color
		},
	},
}
</script>
