<template>
	<b-modal
		hide-footer
		centered
		size="xs"
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
		body-class="m-0 p-0"
	>
  		<div class="card m-1 p-1">
			<!-- <b-form-datepicker v-model="info.date" :disabled="isLoading" class="mb-2"></b-form-datepicker> -->
			<small>
				<span>{{ info.tournament }}</span>
			</small>
			<hr />

			<div class="p-1">
				<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
					<b-form-group label="Fecha: " label-for="input-formatter" class="mb-0">
						<b-form-datepicker
							class="border-primary mb-2"
							locale="en-US"
							placeholder="Agregar final"
							:date-format-options="{
								month: 'numeric',
								day: 'numeric',
								year: 'numeric',
							}"
							v-model="infoNoReactive.date"
						/>
					</b-form-group>
					<small class="text-danger">
						{{ errors[0] }}
					</small>
				</ValidationProvider>

				<b-button :disabled="isLoading" variant="primary" @click="updateDate">
					<feather-icon icon="SaveIcon" class="icon mr-50" />
					<span class="align-middle">Guardar</span>
				</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import moment from "moment"
export default {
	name: "UpdateDateEvent",
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			title: "",
		}
	},
	computed: {
		infoNoReactive() {
			const info = JSON.parse(JSON.stringify(this.info))
			return info
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async updateDate() {
			this.isLoading = true
			const resp = await tournamentService.updateDate({
				date: moment(this.infoNoReactive.date).format("YYYY-MM-DD"),
				id: this.infoNoReactive.id,
			})
			this.$emit("refresh")
			this.isLoading = false
			this.isActive = false
			this.$emit("closing")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `Actualizar fecha - ${moment(this.infoNoReactive.date).format("MM/DD/YYYY")}`
			}
		},
	},
}
</script>
