<template>
	<b-modal
		no-close-on-backdrop
		centered
		:title="`Editar colaboradores / ${info.tournament + ' ' + info.date || ''}`"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<b-row class="mb-1" v-for="collaborator in collaborators" :key="collaborator.role">
				<b-col cols="6">
					<b-form-input :value="collaborator.role" disabled />
				</b-col>
				<b-col cols="6">
					<v-select
						label="name"
						placeholder="Seleccionar colaborador"
						:clearable="false"
						:options="collaboratorOpts"
						:reduce="(opt) => opt.id"
						v-model="collaborator.value"
					/>
				</b-col>
			</b-row>
		</b-overlay>
		<template #modal-footer>
			<b-button @click="updateCollaborators" variant="success">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import tournamentService from "@/services/tournament/default.service";

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		collaborators: [],
		collaboratorOpts: [],
	}),
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.isLoading = false;
			this.collaborators = []
			this.$emit("closing");
		},
		async getRoles() {
			this.isLoading = true;
			const { data: collaborators } = await tournamentService.getCollaborators();
			this.collaboratorOpts = collaborators.data;
			const { data } = await tournamentService.getCollaboratorRoles();
			this.collaborators = data.data.map((role) => {
				// let match = this.info.collaborators.find((c) => c.p == role.code);
				// Tiene que traer tambien el in del colaborador
				return {
					role_id: role.id,
					role: role.name,
					user: null,
				};
			});
			this.isLoading = false;
		},
		async updateCollaborators() {
			this.isLoading = true;
			let collaborators = this.collaborators
				.filter((it) => it.value)
				.map((c) => ({
					role_id: c.role_id,
					user_id: c.value,
				}));
			await tournamentService.updateCollaborators({
				event_id: this.info.id,
				collaborators,
			});
			this.isLoading = false;
			this.isActive = false;
			this.$emit("refresh");
		},
		async initCollaboratorByEvent() {
			this.isPreloading();
			const { data } = await tournamentService.getCollaboratorsByEventId({ id: this.info.id });
			this.collaborators = this.collaborators.map((it) => {
				const user = data.find((us) => us.tournament_role_id == it.role_id);
				return {
					value: user ? user.user_id : null,
					role: it.role,
					role_id: it.role_id,
				};
			});
			this.isPreloading(false);
		},
	},
	watch: {
		async show(val) {
			if (val) {
				await this.getRoles();
				this.initCollaboratorByEvent();
				this.isActive = val;
			}
		},
	},
};
</script>

<style scoped lang="scss"></style>
