<template>
	<b-modal no-close-on-backdrop centered :title="title" v-model="isActive" @hidden="handleHidden" size="lg">
		<b-overlay :show="isLoading">
			<validation-observer ref="form">
				<b-row class="mb-1" v-for="(perDiem, index) in perDiems" :key="index">
					<b-col :cols="[1, 2].includes(tab) || expensesActive == 2 ? '6' : '5'">
						<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
							<v-select
								:options="arrayPerDiem"
								label="name"
								:reduce="(opt) => opt.id"
								v-model="perDiem.per_diem_id"
								placeholder="Seleccionar viatico"
								@input="($event) => selectPerDiem($event, index)"
								:class="{ 'border-danger rounded': errors[0] }"
								:disabled="[1, 2].includes(tab) || expensesActive == 2"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</ValidationProvider>
					</b-col>
					<b-col :cols="[1, 2].includes(tab) || expensesActive == 2 ? '6' : '5'">
						<ValidationProvider rules="zero" v-slot="{ errors }" class="w-100">
							<money
								v-bind="money"
								v-model="perDiem.amount"
								class="form-control ml-50"
								:class="[expensesActive == 1 ? 'border-primary' : '']"
								placeholder="Agregar monto"
								:style="{ width: '100%', textAlign: 'center' }"
								:disabled="expensesActive == 2"
							/>
							<small>{{ errors[0] }}</small>
						</ValidationProvider>
					</b-col>
					<b-col cols="2" v-if="![1, 2].includes(tab) && expensesActive == 1">
						<div class="d-flex h-100 align-items-center justify-content-center">
							<FeatherIcon
								v-b-tooltip.hover
								title="Quitar viático"
								@click="deletePerDiem(index)"
								icon="TrashIcon"
								size="18"
								class="text-danger cursor-pointer"
							/>
						</div>
					</b-col>
				</b-row>
				<b-row v-if="![1, 2].includes(tab) && expensesActive == 1">
					<b-col>
						<b-button variant="outline-primary" @click="addPerdDiem">
							<feather-icon icon="PlusIcon" />
						</b-button>
					</b-col>
				</b-row>
			</validation-observer>
		</b-overlay>
		<template #modal-footer>
			<div class="d-flex justify-content-between w-100 align-items-center">
				<div>
					<b>TOTAL: S./ {{ totaPerdDiem }}</b>
				</div>
				<div v-if="expensesActive == 1">
					<b-button v-if="![1, 2].includes(tab)" variant="primary" @click="savePerDiemsToEvent(1)">
						Guardar
					</b-button>
					<b-button
						v-if="![1, 2].includes(tab)"
						variant="success"
						@click="savePerDiemsToEvent(2)"
						class="ml-1"
					>
						Finalizar
					</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { VMoney, Money } from "v-money"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import perDiemService from "@/services/tournament/perDiem.service"
import tournamentService from "@/services/tournament/default.service"
import moment from "moment"

export default {
	components: { SpecimenPlate, Money },
	directives: { money: VMoney },
	props: {
		show: Boolean,
		info: Object,
		tab: Number,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		perDiems: [
			{
				per_diem_id: null,
				amount: 0,
			},
		],
		arrayPerDiem: [],
		money: {
			decimal: ",",
			thousands: ".",
			prefix: "S./ ",
			suffix: "",
			precision: 2,
			masked: false,
		},
		expensesActive: 1,
		title: null,
	}),
	computed: {
		totaPerdDiem() {
			return this.perDiems
				.map((it) => it.amount)
				.reduce(
					(accumulator, currentValue) =>
						parseFloat(accumulator ? accumulator : 0) + parseFloat(currentValue ? currentValue : 0),
					0
				)
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.perDiems = []
			this.$emit("closing")
		},
		async initPerDiem() {
			const { data } = await perDiemService.getPerDiem({ status: 1 })
			this.arrayPerDiem = data
		},
		addPerdDiem() {
			this.perDiems.push({
				per_diem_id: null,
				amount: 0,
			})
		},
		selectPerDiem(e, index) {
			const exist = this.perDiems.some((it, indexPerDiem) => it.per_diem_id == e && indexPerDiem != index)
			if (exist) {
				this.showToast("warning", "top-right", "Viáticos", "WarningIcon", "Ya existe ese viático")
				this.perDiems[index].per_diem_id = null
			}
		},
		async savePerDiemsToEvent(status) {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}
			// console.log("per diems", this.perDiems);

			if (status == 2) {
				const { isConfirmed } = await this.showConfirmSwal()
				if (!isConfirmed) return
			}

			this.isPreloading()

			await tournamentService.AddPerDiemToEvent({
				event_id: this.info.id,
				per_diems: this.perDiems.map((it) => ({ id: it.per_diem_id, amount: it.amount })),
				status: status,
			})

			this.$emit("refresh")

			this.isActive = false
			this.isPreloading(false)
		},
		async getPerDiemsByEvent() {
			const { data } = await tournamentService.getPerDiemsByEvent({ id: this.info.id })

			// console.log('DATA', data);
			if (data.length != 0) {
				this.perDiems = data.map((it) => ({ ...it, amount: it.amount.toFixed(2) }))
				this.expensesActive = this.perDiems[0].per_diem_status_id
			} else {
				this.perDiems = [
					{
						per_diem_id: null,
						amount: 0,
					},
				]
				this.expensesActive = 1
			}
			console.log("dara", this.expensesActive)
		},
		deletePerDiem(index) {
			if (this.perDiems.length == 1) {
				this.showToast("warning", "top-right", "Viáticos", "WarningIcon", "Debe haber al menos un viático.")
				return
			}
			this.perDiems = this.perDiems.filter((it, ind) => ind != index)
		},
		formatDate(date) {
			return moment(date).format("MM-DD-YY")
		},
	},
	watch: {
		async show(val) {
			if (val) {
				this.isPreloading()
				this.isActive = val
				await this.initPerDiem()
				await this.getPerDiemsByEvent()
				this.title = `Viáticos / ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
				this.isPreloading(false)
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
