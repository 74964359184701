<template>
	<b-modal centered hide-footer size="xl" title="Fechas proximas" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<EventsN :season="info.season" :ownChild="info.isOwnChild" :forModal="true" v-if="isActive" />
		</b-overlay>
	</b-modal>
</template>

<script>
export default {
	name: "Iem",
	components: { EventsN: () => import("../../tournaments/v2/views/Events.vue") },
	props: { show: Boolean, info: Object },
	data: () => ({
		isActive: false,
		isLoading: false,
	}),
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false
			this.selected = null
			this.$emit("closing")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
			}
		},
	},
}
</script>
