// import { amgApi } from "@/service/axios";

import axios from "@/axios";

const url = "/api/tournament/stages";

class TournamentStagesService {
	async index() {
		try {
			const data = await axios.get(url);
			return data;
		} catch (error) {
			console.log("error", error);
		}
	}
}
export default new TournamentStagesService();
