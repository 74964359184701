var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","title":_vm.title,"hide-footer":_vm.info.status_id == 3},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success","disabled":_vm.isLoading},on:{"click":_vm.sendAwards}},[_vm._v("Guardar")])]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('ValidationObserver',{ref:"form"},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Premios "),(_vm.info.status_id != 3)?_c('b-button',{staticClass:"btn-icon ml-1",attrs:{"variant":"flat-success","size":"sm"},on:{"click":function($event){return _vm.awards.push({ type: null, value: 0 })}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"18"}})],1):_vm._e()],1),_c('div',{staticStyle:{"max-height":"10rem","overflow-y":"auto","overflow-x":"hidden"}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"5"}},[_c('h6',{staticClass:"text-center"},[_vm._v("Tipo")])]),_c('b-col',{attrs:{"cols":"5"}},[_c('h6',{staticClass:"text-center"},[_vm._v("Premio")])]),_c('b-col',{attrs:{"cols":"2"}})],1),_vm._l((_vm.awards),function(award,i){return _c('b-row',{key:i,staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{class:{ 'border-danger ': errors[0] },attrs:{"disabled":_vm.info.status_id == 3,"options":_vm.awardTypeOpts},on:{"input":function($event){award.type == 1
										? (award.value = award.value != 0 ? award.value : 0)
										: award.value == 0
										? (award.value = '')
										: null}},model:{value:(award.type),callback:function ($$v) {_vm.$set(award, "type", $$v)},expression:"award.type"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"5"}},[(award.type == null)?_c('b-form-input',{attrs:{"disabled":""}}):(award.type == 1)?_c('ValidationProvider',{attrs:{"rules":"required|zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control text-center",class:{ 'border-danger ': errors[0] },attrs:{"placeholder":"Agregar monto"},model:{value:(award.value),callback:function ($$v) {_vm.$set(award, "value", _vm._n($$v))},expression:"award.value"}},'money',_vm.money,false))]}}],null,true)}):_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text-center",class:{ 'border-danger': errors[0] },model:{value:(award.value),callback:function ($$v) {_vm.$set(award, "value", $$v)},expression:"award.value"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"2"}},[(_vm.info.status_id != 3)?_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-danger","size":"sm"},on:{"click":function($event){return _vm.awards.splice(i, 1)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"18"}})],1):_vm._e()],1)],1)})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }