<template>
	<b-modal
		hide-footer
		centered
		size="lg"
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
		body-class="m-0 p-0"
	>
		<b-overlay :show="isLoading">
			<b-row class="p-0 m-0">
				<b-col cols="12" lg="6" class="p-2">
					<!-- {{ payEvent }} -->

					<div>
						<b-row>
							<b-col cols="6" class="mb-1">
								<span class="font-weight-bolder">Etapa:</span>
								{{ info.stage }}
							</b-col>

							<b-col cols="6" class="mb-1">
								<span class="font-weight-bolder">Por frente:</span>
								{{ info.currency.name }} {{ info.price_stage }}
							</b-col>

							<b-col cols="6" class="mb-1">
								<span class="font-weight-bolder">Total:</span>
								{{ info.currency.name }} {{ info.amount }}
							</b-col>

							<b-col cols="6" class="mb-1">
								<span class="font-weight-bolder">Fecha:</span>
								{{ returnDate(info.date) }}
							</b-col>
							<b-col cols="6" class="mb-1">
								<span class="font-weight-bolder">Coliseo:</span>
								{{ info.coliseum }}
							</b-col>
						</b-row>
					</div>

					<ValidationObserver ref="formFronts">
						<b-row class="mt-1">
							<b-col cols="12">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-form-group>
										<div class="d-flex align-items-center justify-content-between mb-50">
											<h4 style="font-size: 14px; font-weight: 400" class="mb-0">
												Frentes a pagar
											</h4>
										</div>
										<!--  -->
										<v-select
											label="front_name"
											:class="{ 'border-danger rounded': errors[0] }"
											:reduce="(opt) => opt.front_id"
											:clearable="false"
											:options="frontsSelectable"
											v-model="form.front_id"
											appendToBody
											:calculatePosition="positionDropdown"
											placeholder="Elegir frente"
											:selectable="(opt) => !opt.check && isNotFinished"
											@input="selectfront"
										/>
									</b-form-group>
								</ValidationProvider>
							</b-col>
							<b-col cols="12">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-form-group label="Beneficiario">
										<v-select
											label="name"
											:class="{ 'border-danger rounded': errors[0] }"
											:reduce="(opt) => opt.id"
											:clearable="false"
											:options="beneficiaryOpts"
											v-model="form.beneficiary_id"
											appendToBody
											:calculatePosition="positionDropdown"
											@search="fetchOpts"
											placeholder="Seleccionar"
										/>
									</b-form-group>
								</ValidationProvider>
							</b-col>
							<b-col cols="12">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-form-group label="Tipo de pago">
										<v-select
											:class="{ 'border-danger rounded': errors[0] }"
											label="text"
											:reduce="(opt) => opt.id"
											:clearable="false"
											:options="[
												{ id: 2, text: 'Efectivo' },
												{ id: 1, text: 'Transferencia' },
											]"
											v-model="form.type_id"
											appendToBody
											:calculatePosition="positionDropdown"
											placeholder="Seleccionar"
										/>
									</b-form-group>
								</ValidationProvider>
							</b-col>
							<b-col cols="12" v-if="form.type_id == 1">
								<SelectableImage v-model="form.evidence" />
							</b-col>
						</b-row>
					</ValidationObserver>

					<b-row class="mt-2">
						<b-col cols="12">
							<b-button :disabled="event.pay_status == 3" variant="outline-primary" @click="clearForm">
								Limpiar
							</b-button>
							<b-button
								:disabled="event.pay_status == 3"
								class="ml-1"
								variant="primary"
								@click="savePaymentFront"
							>
								Guardar
							</b-button>
						</b-col>
					</b-row>
				</b-col>

				<b-col
					cols="12"
					lg="6"
					class="d-flex flex-column justify-content-between"
					style="background: #f3f2f7; overflow-y: auto; height: 500px"
				>
					<div class="p-2 flex-1 d-flex flex-column align-items-center container-payments">
						<template v-for="(front, index) in fronts">
							<div v-if="front.beneficiary_id" class="container-pay-front" :key="index">
								<div
									class="header bg-primary p-1 d-flex align-items-center w-100 justify-content-between"
								>
									<h4 class="text-white mb-0">
										{{
											front.front_id ? `Frente #${index + 1} / Pago` : "Pago del evento completo"
										}}
									</h4>

									<feather-icon
										v-if="event.pay_status != 3"
										class="text-white cursor-pointer"
										v-b-tooltip.hover="`Eliminar pago`"
										@click="front.front_id == 0 ? removePaymentEvent() : removePaymentFront(front)"
										icon="XIcon"
									/>
								</div>
								<div class="body bg-white p-1">
									<div class="d-flex align-items-center justify-content-between">
										<p class="mb-0">Beneficiario</p>
										<span class="font-weight-bolder text-beneficiary">
											{{ front.beneficiary_name }}
										</span>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<p class="mb-0">Tipo de pago</p>
										<span class="font-weight-bolder text-beneficiary">{{ front.type_name }}</span>
									</div>
									<div class="d-flex align-items-center justify-content-between">
										<p class="mb-0">Monto pagado</p>
										<span class="font-weight-bolder text-beneficiary">
											<!-- {{ front }} -->
											{{front.currency.name}} {{front.amount}}
										</span>
									</div>
									<div
										class="container-image cursor-pointer"
										v-if="front.evidence"
										@click="
											selectImage.items = [{ src: front.evidence, title: 'Evidencia del pago' }]
											selectImage.index = 0
										"
										v-b-tooltip.hover
										title="Ver evidencia"
									>
										<div class="d-flex align-items-center">
											<div class="container-file bg-white">
												<feather-icon class="text-primary" icon="FileIcon" size="24" />
											</div>
											<p class="mb-0 ml-50 font-weight-bold">image.png</p>
										</div>

										<div
											class="bg-primary icon-check-file-pay"
											style="border-radius: 50%; width: 24px; height: 24px"
										>
											<feather-icon class="text-white" icon="CheckIcon" />
										</div>
									</div>
								</div>
								<!-- {{front}} -->
							</div>
						</template>

						<custom-light-box
							:items="selectImage.items"
							:index="selectImage.index"
							@close="selectImage.index = null"
						/>

						<div v-if="fronts.filter((it) => it.check).length == 0">
							<p class="mb-0 font-weight-bolder">Sin pagos registrados</p>
						</div>
					</div>

					<div class="p-2 btns-payment" v-if="event.pay_status != 3">
						<!-- {{ isNotFinished }} -->
						<b-button :disabled="isNotFinished" variant="primary" @click="finishedPays">Finalizar</b-button>
					</div>
				</b-col>
			</b-row>
		</b-overlay>
	</b-modal>
</template>

<script>
import moment from "moment"
import { getBase64 } from "@/helpers/functions"

import tournamentService from "@/services/tournament/default.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import InputImages from "@/components/commons/InputImages.vue"
import SelectableImage from "@/views/amg/specimens/components/SelectableImage.vue"

export default {
	components: { SpecimenPlate, InputImages, SelectableImage },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		event: {},
		fronts: [],
		showFormEvent: false,
		beneficiaryOpts: [],
		form: {
			front_id: null,
			event_id: null,
			beneficiary_id: null,
			type_id: null,
			evidence: null,
			type_front: null,
		},
		tabIndex: 0,
		selectImage: {
			items: [],
			index: null,
		},
		frentes: [
			{
				id: 1,
				name: "Pagar 1 frente",
			},
			{
				id: 2,
				name: "Pagar restantes",
			},
		],
		frontsSelectable: [],
	}),
	computed: {
		isNotFinished() {
			return this.fronts.filter((it) => it.front_id != 0).some((it) => it.check == 0)
		},
		payEvent() {
			return this.event ? this.event.check : false
		},
		validDeleteFront() {
			return this.fronts.filter((it) => it.front_id != 0 && it.check == 0).length == 0
		},
		validCountFront() {
			console.log("fronts", this.fronts)
			return this.fronts.length > 2
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.event = {}
			this.fronts = []
			this.showFormEvent = false
			this.beneficiaryOpts = []
			this.clearForm()
			this.$emit("closing")
		},
		async getFrontPayments() {
			this.isLoading = true
			const { data } = await tournamentService.getEventPayments({ event_id: this.info.id })
			this.event = {
				beneficiary: data.event.beneficiary_id || null,
				typePayment: data.event.type_id || null,
				evidence: !!data.event.evidence ? [{ url: data.event.evidence }] : [],
				withEvidence: !!data.event.evidence,
				check: !!data.event.check,
				pay_status: data.event.pay_status,
			}
			// if (!!data.event.check) {
			// 	this.beneficiaryOpts.push({
			// 		name: data.event.beneficiary_name,
			// 		id: data.event.beneficiary_id,
			// 	})
			// 	this.showFormEvent = true
			// }
			this.fronts = data.fronts.map((it) => ({ ...it, check: this.event.check ? this.event.check : it.check }))

			this.fronts.push({
				front_id: 0,
				check: !!data.event.check,
				evidence: !!data.event.evidence ? data.event.evidence : null,
				code: null,
				type_id: data.event.type_id || null,
				type_name: data.event.type_name || null,
				beneficiary_id: data.event.beneficiary_id || null,
				beneficiary_name: data.event.beneficiary_name || null,
				created_by: data.event.created_by || null,
				updated_by: data.event.updated_by || null,
				currency:this.info.currency,
				amount:this.info.amount,

				
			})

			this.frontsSelectable = data.fronts.map((it, index) => ({
				check: it.check,
				front_id: it.front_id,
				front_name: it.front_id == 0 ? `Pagar evento completo` : `Frente #${index + 1}`,
			}))

			this.frontsSelectable.push({
				check: this.fronts.filter((it) => it.check == 0).length == 0 ? 1 : 0,
				front_id: 0,
				front_name: "Pagar evento completo",
			})

			console.log("fronts", this.fronts)
			this.isLoading = false
		},
		//
		fetchOpts(search, loading) {
			this.findCintillos(search, loading, this.setOpts)
		},
		findCintillos: _.debounce(async (search, loading, setter) => {
			loading(true)
			const { data } = await tournamentService.getPaymentBeneficiaries({ name: search })
			setter(data)
			loading(false)
		}, 500),
		setOpts(data) {
			this.beneficiaryOpts = data.map((opt) => ({ id: opt.id, name: opt.name }))
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		async saveEventPayment() {
			//
			let hasPayedFronts = this.fronts.filter((f) => f.check == 1 && f.front_id != 0).length > 0
			let msg
			if (hasPayedFronts) {
				msg = `<span>Se guardara pago del evento, esto <b>ELIMINARA</b> los pagos registrados anteriormente</span>`
			} else {
				msg = `<span>Se guardara pago del evento</span>`
			}
			if (!(await this.$refs.formFronts.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				html: msg,
			})
			if (!isConfirmed) return
			this.isLoading = true
			const fronts_ids = this.fronts.filter((it) => it.front_id != 0 && it.check == 0).map((it) => it.front_id)
			const events = [
				{
					event_id: this.info.id,
					type_id: this.form.type_id,
					beneficiary_id: this.form.beneficiary_id,
					evidence: this.form.evidence ? await getBase64(this.form.evidence) : null,
					fronts: fronts_ids,
				},
			]
			await tournamentService.upsertEventsPayment({ events })
			this.isLoading = false
			this.showToast(
				"success",
				"top-right",
				"Pagos / Torneos",
				"CheckIcon",
				`Se guardo el pago de forma correcta ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
			)
			this.getFrontPayments()
			this.$emit("refresh")
			this.clearForm()
		},
		async saveFrontPayments() {
			if (!(await this.$refs.formFronts.validate())) return

			const { isConfirmed } = await this.showConfirmSwal({
				text: "Se guardara pago de los frentes",
			})
			if (!isConfirmed) return
			this.isLoading = true

			await tournamentService.upsertFrontsPayment({
				fronts: [
					{
						front_id: this.form.front_id,
						type_id: this.form.type_id,
						beneficiary_id: this.form.beneficiary_id,
						evidence: this.form.evidence ? await getBase64(this.form.evidence) : null,
					},
				],
				status_id: 2,
				event_id: this.info.id,
			})
			this.isLoading = false
			this.showToast(
				"success",
				"top-right",
				"Pagos / Torneos",
				"CheckIcon",
				`Se guardo el pago de forma correcta ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
			)
			this.getFrontPayments()
			this.$emit("refresh")
			this.clearForm()
		},
		async saveFrontsMasivePayments() {
			if (!(await this.$refs.formFronts.validate())) return

			const { isConfirmed } = await this.showConfirmSwal({
				text: "Se guardara pago de los frentes",
			})
			if (!isConfirmed) return
			this.isLoading = true

			const fronts_ids = this.fronts.filter((it) => it.front_id != 0 && it.check == 0).map((it) => it.front_id)
			const evidence = this.form.evidence ? await getBase64(this.form.evidence) : null

			// console.log('ids', fronts_ids);
			// return;

			await tournamentService.upsertFrontsPayment({
				fronts: fronts_ids.map((it) => ({
					front_id: it,
					type_id: this.form.type_id,
					beneficiary_id: this.form.beneficiary_id,
					evidence,
				})),
				status_id: 2,
				event_id: this.info.id,
			})
			this.isLoading = false
			this.showToast(
				"success",
				"top-right",
				"Pagos / Torneos",
				"CheckIcon",
				`Se guardo el pago de forma correcta ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
			)
			this.getFrontPayments()
			this.$emit("refresh")
			this.clearForm()
		},
		async removePaymentEvent() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: "Se eliminara pago de evento",
			})
			if (!isConfirmed) return
			this.isLoading = true
			await tournamentService.removePaymentEvent({ id: this.info.id })
			this.isLoading = false
			this.showToast(
				"success",
				"top-right",
				"Pagos / Torneos",
				"CheckIcon",
				`Se eliminó el pago de forma correcta ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
			)
			this.getFrontPayments()
			this.$emit("refresh")
		},
		async removePaymentFront(front) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: "Se eliminara pago de frente",
			})
			if (!isConfirmed) return
			this.isLoading = true
			await tournamentService.removePaymentFront({ id: front.front_id })
			this.isLoading = false
			this.showToast(
				"success",
				"top-right",
				"Pagos / Torneos",
				"CheckIcon",
				`Se eliminó el pago de forma correcta ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
			)
			this.getFrontPayments()
			this.$emit("refresh")
		},
		async removeFront(front) {
			// const { isConfirmed } = await this.showConfirmSwal({
			// 	text: `Se eliminara un frente de ${this.info.tournament}`,
			// })
			// if (!isConfirmed) return
			// this.isLoading = true
			// await tournamentService.removeFront({ id: front.id })
			// this.isLoading = false
			// this.getFrontPayments()
			// this.$emit("refresh")
		},
		async finishedPays() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: "Se guardara pago de los frentes",
			})
			if (!isConfirmed) return
			this.isLoading = true

			await tournamentService.upsertFrontsPayment({
				fronts: [],
				status_id: 3,
				event_id: this.info.id,
			})
			this.isLoading = false
			this.getFrontPayments()
			this.$emit("refresh")
			this.clearForm()
		},
		clearForm() {
			this.form = {
				front_id: null,
				event_id: null,
				beneficiary_id: null,
				type_id: null,
				evidence: null,
			}
			this.$refs.formFronts && this.$refs.formFronts.reset()
		},
		async savePaymentFront() {
			if (this.form.front_id != 0) {
				this.saveFrontPayments()
			} else {
				this.saveEventPayment()
			}
		},
		async removeOneFront() {
			const fronts_ids = this.fronts.filter((it) => it.check == 0 && it.front_id != 0).map((it) => it.front_id)

			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se eliminara un frente de ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`,
			})
			if (!isConfirmed) return
			this.isLoading = true

			// return;
			await tournamentService.removeFront({ id: fronts_ids[fronts_ids.length - 1] })
			this.isLoading = false
			this.showToast(
				"success",
				"top-right",
				"Pagos / Torneos",
				"CheckIcon",
				`Se eliminó un frente de forma correcta ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
			)
			this.getFrontPayments()
			this.$emit("refresh")
		},
		selectfront(e) {
			// const existFrontPay = this.fronts.filter((it) => it.front_id != 0).some((it) => it.check)
			// if (existFrontPay && e == 0) {
			// 	this.showToast(
			// 		"warning",
			// 		"top-right",
			// 		"Pagos / Torneos",
			// 		"CheckIcon",
			// 		`No se puede pagar completo, porque ya existe un frente pagado`
			// 	)
			// 	this.form.front_id = null
			// 	this.$refs.formFronts && this.$refs.formFronts.reset()
			// 	return
			// }
		},
		returnDate(date) {
			return moment(date).format("MM-DD-YYYY")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `Registrar pagos / ${this.info.tournament}`
				this.getFrontPayments()
				this.findCintillos("", () => {}, this.setOpts)
			}
		},
	},
}
</script>

<style lang="scss">
.container-pay-front {
	border-radius: 12px !important;
	overflow: hidden;
	margin-bottom: 1rem;
	// width: 80%;
	width: 100%;
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	&:last-child {
		margin-bottom: 0;
	}
	.header {
		h4 {
			font-size: 16px;
		}
	}
	.body {
		.container-image {
			border: 1px #7367f0 solid;
			border-radius: 8px;
			margin-top: 1rem;
			background: rgba($color: #7367f0, $alpha: 0.3);
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1rem;
			.container-file {
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 8px;
			}
			.icon-check-file-pay {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.text-beneficiary {
		text-align: right;
	}
}

.container-payments {
	// min-height: 1000px;
	// overflow: scroll;
}

.btns-payment {
	position: sticky;
	bottom: 0;
	background: #f3f2f7;
}
</style>
