<template>
	<b-modal
		ref="modal-Create"
		@hidden="$emit('close')"
		cancel-title="Cancelar"
		scrollable
		size="xlg"
		no-close-on-backdrop
		:title="`${'FIXTURES'}`"
	>
		<div ref="diagramContent" style="display: flex">
			<div class="container-diagram" style="position: relative">
				<div class="container-text">
					<div
						class="container-logotext"
						style="display: flex; align-items: center; gap: 1rem; background-color: rgba(240, 242, 255, 1)"
					>
						<div style="width: 80px; height: 80px">
							<img
								:src="`data:image/jpeg;base64,${avatar}`"
								alt="logo"
								style="object-fit: cover; width: 100%; height: 100%; border-radius: 50%"
							/>
						</div>

						<div>
							<div style="width: 530px">
								<h1 style="font-size: 24.36px; font-weight: 900; color: rgba(0, 0, 0, 1)">
									{{ infoDiagram.tournament }}
								</h1>
							</div>
							<ul class="container-ul">
								<li style="font-size: 16px">Fecha: {{ infoDiagram.date | formatoPersonalizado }}</li>
							</ul>
						</div>
					</div>

					<div style="position: relative">
						<div class="container-logo">
							<img src="@/assets/images/logoDiagram.png" alt="logo" />
						</div>
						<img src="@/assets/images/lineess.png" alt="logo" class="liness" />
					</div>
					<div class="line-text"></div>
				</div>

				<div class="containers-alert not-capture">
					<div class="container-alert not-capture">
						<h4 class="text-center" style="color: rgba(128, 149, 255, 1)">ÚLTIMA ACTUALIZACIÓN</h4>
						<ul
							v-if="dates != null"
							style="display: flex; flex-direction: column; align-items: center; padding: 0px"
						>
							<li style="color: rgba(72, 72, 72, 1)  ;font-weight: 800">
								 
								Usuario: {{ dates.user }}
							</li>
							<li style="color: rgba(72, 72, 72, 1) ;font-weight: 800">
 								FECHA: {{ dates.date | myGlobalDayWithHour }}
							</li>
						</ul>
						<div v-else class="d-flex justify-content-center p-1">
							<span>Sin actualizar</span>
						</div>
						<div class="line-alert"></div>
					</div>
				</div>

				<div style="display: flex; gap: 2rem; min-width: 1472px" ref="wrapper">
					<div
						style="display: flex; flex-direction: column; width: 300px; margin-top: 0px"
						class="not-for-capture container-sticky"
					>
						<div class="container-grid containerss">
							<div
								style="display: flex; justify-content: center; padding: 0.7rem"
								class="text-participant2"
							>
								<span style="font-size: 1.2rem; color: rgba(255, 255, 255, 1); font-weight: 800">
									PARTICIPANTES
								</span>
							</div>
						</div>

						<div style="padding: 0.5rem; padding-bottom: 1rem; max-height: 50vh">
							<div class="container-div">
								<template v-for="(part, index) in participant">
									<draggable
										:list="[part]"
										tag="div"
										:group="{
											name: 'people',
											pull: 'clone',
											put: false,
										}"
										v-bind="{
											animation: 0,
											disabled: part.in_use == 1 || part.participant_id == null || tab != 0,
										}"
										@start="onDragPart(part, $event)"
										@end="handleEndPart($event, participant)"
										style="
											height: 38.65px;
											font-weight: 800;
											display: flex;
											align-items: center;
											padding-left: 0.5rem;
											padding-right: 0.5rem;
											gap: 2rem;
											font-size: 10px;
										"
										:key="index"
									>
										<!-- <div> -->
										<div
											style="display: flex; justify-content: space-between; width: 100%"
											:class="{ 'cursor-move': part.in_use == 0 && tab == 0 }"
											:key="index"
										>
											<div
												style="
													padding: 0.8rem;
													background-color: rgba(238, 238, 238, 1);
													width: 100%;
													border-radius: 5px;
													display: flex;
												"
											>
												<span class="transparents">.</span>
												<div
													class="flex"
													style="
														gap: 0.3rem;
														align-items: center;
														justify-content: space-between;
														width: 100%;
													"
												>
													<span v-if="part.name">{{ index + 1 }}. {{ part.name }}</span>

													<i
														v-b-tooltip.hover.top="'Asignado'"
														v-if="part.in_use"
														style="
															color: #8095ff;
															font-size: 1.2rem;
															font-weight: 800;
															z-index: 999;
														"
														class="fa-solid fa-check"
													></i>
												</div>
											</div>
										</div>
										<!-- </div> -->
									</draggable>
								</template>
							</div>
						</div>
					</div>

					<div class="wrapper" ref="wrapper2">
						<div v-for="(item, index) in tournament" :key="index" class="item">
							<div
								class="item-parent"
								:class="{
									check: item.participant_id,
									deleteCheck: stage != '4',
								}"
								style="display: flex; position: relative"
							>
								<div class="img-winner" v-if="stage == '4'">
									<!-- <img src="@/assets/images/cup.png" alt="logo" /> -->
									<img
										src="@/assets/images/cup.png"
										alt="logo"
										style="
											object-fit: contain !important;
											width: 100%;
											height: 100%;
											border-radius: 50%;
										"
									/>
								</div>
								<div class="parent-winner" :class="stage == '4' ? 'active' : 'transparents'">
									<span v-if="stage == '4'">{{ item.name }}</span>

									<div class="radio-button not-capture" @click="changeStage(4)">
										<div :class="`radio-button-icons ${stage == 4 ? 'active' : 'desactive'}`"></div>
									</div>
								</div>

								<img
									v-if="stage == '4'"
									class="champion"
									src="@/assets/images/left.png"
									alt="logo"
									style="width: auto; height: auto"
								/>
								<img
									v-if="stage == '4'"
									class="champion1"
									src="@/assets/images/rigth.png"
									alt="logo"
									style="width: auto; height: auto"
								/>
							</div>
							<div class="item-childrens">
								<div
									v-for="(child, childIndex) in item.parents"
									:key="childIndex"
									class="item-child-final"
									:class="`${
										child.is_played === 1 && child.is_winner === 1 && stage == '4'
											? 'has-label'
											: child.is_played === 1 && child.is_winner === 0
											? 'defeat'
											: ''
									}`"
								>
									<div class="item">
										<div
											class="item-parent"
											:class="{
												check: child.participant_id,
												deleteCheck: stage != '3' && stage != '4',
											}"
										>
											<div
												class="container-texts"
												:class="{
													active: stage == '3',
													transparents: stage != '3' && stage != '4',
												}"
											>
												<div
													class="img-winner"
													v-if="stage == '3'"
													style="top: -2rem; width: 50px; height: 50px"
												>
													<!-- <img src="@/assets/images/cup.png" alt="logo" /> -->
													<img
														src="@/assets/images/cup.png"
														alt="logo"
														style="
															object-fit: contain;
															width: 100%;
															height: 100%;
															border-radius: 50%;
														"
													/>
												</div>
												<span class="w-100" v-if="stage == '3' || stage == '4'">
													{{ child.name }}
												</span>
												<div
													class="radio-button not-capture"
													v-if="childIndex == 0 && child.group == 1"
													@click="changeStage(3)"
												>
													<div
														:class="`radio-button-icons ${
															stage == 3 ? 'active' : 'desactive'
														}`"
													></div>
												</div>
												<span
													v-if="
														child.is_played === 1 &&
														child.is_winner === 1 &&
														stage != '3' &&
														stage != '1' &&
														stage != '2'
													"
												>
													<i
														v-b-tooltip.hover.top="'Victoria'"
														class="fa-solid fa-flag"
														style="color: #28c76f; font-size: 1.2rem; padding-right: 0.3rem"
													></i>
												</span>

												<span
													v-if="
														child.id !== null &&
														child.is_played === 0 &&
														child.is_winner === 0 &&
														tab === 0 &&
														!hideIcons &&
														stage != '3' &&
														stage != '1' &&
														stage != '2'
													"
													@click="assingWinner(child)"
													class="cursor-pointer not-capture"
												>
													<i
														v-b-tooltip.hover.top="'Asignar ganador'"
														class="fa-solid fa-medal"
														style="color: red; font-size: 0.8rem"
													></i>
												</span>
												<span
													v-if="
														child.is_played === 1 &&
														child.is_winner === 0 &&
														stage != '3' &&
														stage != '1' &&
														stage != '2'
													"
													@click="item.is_played && changeWinner(child)"
													class="cursor-pointer"
													:class="`${item.is_played == 0 ? 'cursor-pointer' : ''}`"
												>
													<i
														v-b-tooltip.hover.top="'Derrota'"
														class="fa-solid fa-flag"
														style="color: red; font-size: 1.2rem; padding-right: 0.3rem"
													></i>
												</span>
											</div>
											<img
												v-if="stage == '3'"
												class="champion champion-tops"
												src="@/assets/images/left.png"
												alt="logo"
												style="width: auto; height: auto"
											/>
											<img
												v-if="stage == '3'"
												class="champion1 champion-tops"
												src="@/assets/images/rigth.png"
												alt="logo"
												style="width: auto; height: auto"
											/>
										</div>

										<div class="item-childrens">
											<div
												v-for="(grandchild, grandchildIndex) in child.parents"
												:key="grandchildIndex"
												class="item-child-semifinal"
												:class="`${
													grandchild.is_played === 1 &&
													grandchild.is_winner === 1 &&
													stage != '2' &&
													stage != '1'
														? 'has-label'
														: grandchild.is_played === 1 && grandchild.is_winner === 0
														? 'defeat'
														: ''
												}`"
											>
												<div class="item">
													<div
														class="item-parent"
														:class="{
															check: grandchild.participant_id,
															deleteCheck: stage != '2' && stage != '3' && stage != '4',
														}"
													>
														<div
															class="container-texts"
															:class="{
																active: stage == '2',
																transparents:
																	stage != '2' && stage != '3' && stage != '4',
															}"
														>
															<div
																class="img-winner"
																v-if="stage == '2'"
																style="top: -2rem; width: 50px; height: 50px"
															>
																<!-- <img src="@/assets/images/cup.png" alt="logo" /> -->
																<img
																	src="@/assets/images/cup.png"
																	alt="logo"
																	style="
																		object-fit: contain;
																		width: 100%;
																		height: 100%;
																		border-radius: 50%;
																	"
																/>
															</div>
															<span v-if="stage == '2' || stage == '4' || stage == '3'">
																{{ grandchild.name }}
															</span>
															<div
																@click="changeStage(2)"
																class="radio-button not-capture"
																v-if="grandchildIndex == 0 && grandchild.group == 1"
															>
																<div
																	:class="`radio-button-icons ${
																		stage == 2 ? 'active' : 'desactive'
																	}`"
																></div>
															</div>
															<span
																v-if="
																	grandchild.is_played === 1 &&
																	grandchild.is_winner === 1 &&
																	stage != '2' &&
																	stage != '1'
																"
															>
																<i
																	v-b-tooltip.hover.top="'Victoria'"
																	class="fa-solid fa-flag"
																	style="
																		color: #28c76f;
																		font-size: 1.2rem;
																		padding-right: 0.3rem;
																	"
																></i>
															</span>

															<span
																v-if="
																	grandchild.id !== null &&
																	grandchild.is_played === 0 &&
																	grandchild.is_winner === 0 &&
																	tab === 0 &&
																	!hideIcons &&
																	stage != '2' &&
																	stage != '1'
																"
																@click="assingWinner(grandchild)"
																class="cursor-pointer not-capture"
															>
																<i
																	v-b-tooltip.hover.top="'Asignar ganador'"
																	class="fa-solid fa-medal"
																	style="color: red; font-size: 0.8rem"
																></i>
															</span>
															<span
																v-if="
																	grandchild.is_played === 1 &&
																	grandchild.is_winner === 0 &&
																	stage != '2' &&
																	stage != '1'
																"
																@click="
																	child.is_played == 0 && changeWinner(grandchild)
																"
																:class="`${
																	child.is_played == 0 ? 'cursor-pointer' : ''
																}`"
															>
																<i
																	v-b-tooltip.hover.top="'Derrota'"
																	class="fa-solid fa-flag"
																	style="
																		color: red;
																		font-size: 1.2rem;
																		padding-right: 0.3rem;
																	"
																></i>
															</span>
														</div>
														<img
															v-if="stage == '2'"
															class="champion champion-top"
															src="@/assets/images/left.png"
															alt="logo"
															style="width: auto; height: auto"
														/>
														<img
															v-if="stage == '2'"
															class="champion1 champion-top"
															src="@/assets/images/rigth.png"
															alt="logo"
															style="width: auto; height: auto"
														/>
													</div>

													<div class="item-childrens">
														<div
															v-for="(quartos, grandchildIndex) in grandchild.parents"
															:key="grandchildIndex"
															class="item-child-quartos"
															:class="`${
																quartos.is_played === 1 &&
																quartos.is_winner === 1 &&
																stage != '1'
																	? 'has-label'
																	: quartos.is_played === 1 && quartos.is_winner === 0
																	? 'defeat'
																	: ''
															}`"
														>
															<!-- :class="{ 'has-label': !quartos.name }" -->
															<div class="item">
																<div
																	class="item-parent"
																	:class="{
																		check: quartos.participant_id,
																	}"
																>
																	<div
																		class="container-texts"
																		:class="{
																			active: stage == '1',
																		}"
																	>
																		<div
																			class="img-winner"
																			v-if="stage == '1'"
																			style="
																				top: -2rem;
																				width: 50px;
																				height: 50px;
																			"
																		>
																			<!-- <img src="@/assets/images/cup.png" alt="logo" /> -->
																			<img
																				src="@/assets/images/cup.png"
																				alt="logo"
																				style="
																					object-fit: contain;
																					width: 100%;
																					height: 100%;
																					border-radius: 50%;
																				"
																			/>
																		</div>
																		<span>{{ quartos.name }}</span>
																		<div
																			class="radio-button not-capture"
																			v-if="
																				grandchildIndex == 0 &&
																				quartos.group == 1
																			"
																			@click="changeStage(1)"
																		>
																			<div
																				:class="`radio-button-icons ${
																					stage == 1 ? 'active' : 'desactive'
																				}`"
																			></div>
																		</div>

																		<span
																			v-if="
																				quartos.is_played === 1 &&
																				quartos.is_winner === 1 &&
																				stage != '1'
																			"
																		>
																			<i
																				v-b-tooltip.hover.top="'Victoria'"
																				class="fa-solid fa-flag"
																				style="
																					color: #28c76f;
																					font-size: 1.2rem;
																					padding-right: 0.3rem;
																				"
																			></i>
																		</span>

																		<span
																			v-if="
																				quartos.id !== null &&
																				quartos.is_played === 0 &&
																				quartos.is_winner === 0 &&
																				tab === 0 &&
																				!hideIcons &&
																				stage != '1'
																			"
																			@click="assingWinner(quartos)"
																			class="cursor-pointer not-capture"
																		>
																			<i
																				v-b-tooltip.hover.top="
																					'Asignar ganador'
																				"
																				class="fa-solid fa-medal"
																				style="color: red; font-size: 0.8rem"
																			></i>
																		</span>
																		<span
																			v-if="
																				quartos.is_played === 1 &&
																				quartos.is_winner === 0 &&
																				stage != '1'
																			"
																			@click="
																				grandchild.is_played == 0 &&
																					changeWinner(quartos)
																			"
																			:class="`${
																				grandchild.is_played == 0
																					? 'cursor-pointer'
																					: ''
																			}`"
																		>
																			<i
																				v-b-tooltip.hover.top="'Derrota'"
																				class="fa-solid fa-flag"
																				style="
																					color: red;
																					font-size: 1.2rem;
																					padding-right: 0.3rem;
																				"
																			></i>
																		</span>
																	</div>
																	<img
																		v-if="stage == '1'"
																		class="champion champion-quartos"
																		src="@/assets/images/left.png"
																		alt="logo"
																		style="width: auto; height: auto"
																	/>
																	<img
																		v-if="stage == '1'"
																		class="champion1 champion-quartos"
																		src="@/assets/images/rigth.png"
																		alt="logo"
																		style="width: auto; height: auto"
																	/>
																</div>

																<div class="item-childrens">
																	<div class="Draggable-container">
																		<div class="line">
																			{{ quartos.parents[0].group }}
																		</div>
																		<div class="Draggable-style">
																			<template
																				v-for="(
																					participant, participantIndex
																				) in quartos.parents"
																			>
																				<draggable
																					:key="participantIndex"
																					:list="[participant]"
																					tag="div"
																					v-bind="{
																						animation: 0,
																						disabled:
																							!(
																								participant.is_played ===
																									0 &&
																								participant.participant_id !=
																									null &&
																								tab == 0
																							) &&
																							!(
																								participant.id ==
																									null &&
																								isActiveAllDrag
																							),
																					}"
																					@start="onDrag(participant, $event)"
																					@end="
																						handleEnd($event, participant)
																					"
																					@change="
																						onDrop($event, participant)
																					"
																					:group="{
																						name: 'people',
																						pull: 'clone',
																						put: true,
																					}"
																					class="item-child-participant"
																					:class="`${
																						participant.is_played === 1 &&
																						participant.is_winner === 1
																							? 'has-label'
																							: participant.is_played ===
																									1 &&
																							  participant.is_winner ===
																									0
																							? 'defeat'
																							: ''
																					}`"
																				>
																					<div
																						v-if="
																							participant.group ==
																								quartos.parents[
																									participantIndex
																								].group &&
																							participant.alignment ==
																								quartos.parents[
																									participantIndex
																								].alignment
																						"
																						class="d-flex h-full"
																						:class="{
																							'cursor-move':
																								participant.is_played ===
																									0 &&
																								participant.participant_id !=
																									null &&
																								tab == 0,
																						}"
																					>
																						<div class="alignment">
																							{{
																								participant.alignment ==
																								"izquierda"
																									? "I"
																									: "D"
																							}}
																						</div>
																						<div class="container-texts">
																							<span class="w-full">
																								{{ participant.name }}
																							</span>
																							<span
																								v-if="
																									participant.is_played ===
																										1 &&
																									participant.is_winner ===
																										1
																								"
																							>
																								<i
																									v-b-tooltip.hover.top="
																										'Victoria'
																									"
																									class="fa-solid fa-flag"
																									style="
																										color: #28c76f;
																										font-size: 1.2rem;
																										padding-right: 0.3rem;
																									"
																								></i>
																							</span>
																							<span
																								v-if="
																									participant.id !==
																										null &&
																									participant.is_played ===
																										0 &&
																									participant.is_winner ===
																										0 &&
																									tab === 0 &&
																									!hideIcons
																								"
																								@click="
																									assingWinner(
																										participant
																									)
																								"
																								class="cursor-pointer not-capture"
																							>
																								<i
																									v-b-tooltip.hover.top="
																										'Asignar ganador'
																									"
																									class="fa-solid fa-medal"
																									style="
																										color: red;
																										font-size: 0.8rem;
																									"
																								></i>
																							</span>

																							<span
																								v-b-tooltip.hover.top="
																									'Eliminar participante'
																								"
																								v-if="
																									participant.id !==
																										null &&
																									participant.is_played ===
																										0 &&
																									participant.is_winner ===
																										0 &&
																									tab === 0 &&
																									!hideIcons
																								"
																								@click="
																									deleteParticipant(
																										participant
																									)
																								"
																								class="cursor-pointer not-capture delete-participant"
																							>
																								<i
																									class="fa-solid fa-trash"
																									style="
																										color: white;
																										font-size: 0.7rem;
																									"
																								></i>
																							</span>

																							<span
																								v-if="
																									participant.is_played ===
																										1 &&
																									participant.is_winner ===
																										0
																								"
																								@click="
																									quartos.is_played ==
																										0 &&
																										changeWinner(
																											participant
																										)
																								"
																								:class="`${
																									quartos.is_played ==
																									0
																										? 'cursor-pointer'
																										: ''
																								} `"
																							>
																								<i
																									v-b-tooltip.hover.top="
																										'Derrota'
																									"
																									class="fa-solid fa-flag"
																									style="
																										color: red;
																										font-size: 1.2rem;
																										padding-right: 0.3rem;
																									"
																								></i>
																							</span>
																						</div>
																					</div>
																					<!-- </div> -->
																				</draggable>
																			</template>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div>
				<img ref="imagenStyle" src="@/assets/images/mark.png" alt="" class="imageprev" />
			</div>
		</div>
		<template #modal-footer>
			<b-button variant="danger" @click="closeModal">Cerrar</b-button>
			<b-button variant="primary" @click="exportToPDF">Exportar pdf</b-button>
			<b-button variant="primary" @click="captureAsImage">Exportar imagen</b-button>
		</template>
	</b-modal>
</template>
<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import draggable from "vuedraggable"
import tournamentService from "@/views/amg/tournaments/tournament.service.js"
import html2pdf from "html2pdf.js"
import ViewImage from "@/components/commons/ViewImage.vue"
import html2canvas from "html2canvas"

export default {
	components: {
		draggable,
		ViewImage,
	},
	props: {
		infoDiagram: Object,
		tab: Number,
	},
	directives: { Ripple },

	mixins: [modalMixin],
	data() {
		return {
			isLoading: false,
			hideIcons: false,
			tournament: [],
			tournament2: [],
			avatar: null,
			dates: {},
			participant: [],
			stage: null,
			participantMove: {
				action: null,
				participant: null,
			},
			list1: [{ name: "John", id: 1 }],
			list2: [{ name: "Juan", id: 5 }],
			isActiveAllDrag: false,
		}
	},
	async created() {
		this.participant = this.generateNullParticipants()
		await this.getByParticipants()
		await this.getTournament()
	},
	async mounted() {
		this.toggleModal("modal-Create")
	},
	methods: {
		closeModal() {
			this.$emit("close")
		},
		refreshAll() {
			this.tournament = this.tournament2
		},

		async changeWinner(el) {
			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro?`,
				text: `Se asignará el nuevo ganador a ${el.name}.  `,
			})
			if (!confirm.value) return
			try {
				const { data } = await tournamentService.selectWinner(this.infoDiagram.id, el)
				this.showToast("success", "top-right", "Seleccion de Ganador", "SuccessIcon", data.message)
				await this.getTournament()
			} catch (error) {
				console.log("error: ", error)
			}
		},

		async deleteParticipant(el) {
			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro?`,
				text: `Se eliminará al participante ${el.name}.`,
			})
			if (!confirm.value) return

			try {
				const datas = {
					participant: el,
				}
				const { data } = await tournamentService.deleteParticipant(this.infoDiagram.id, datas)
				this.showToast("success", "top-right", "Eliminar participante", "SuccessIcon", data.message)
				await this.getTournament()
				await this.getByParticipants()
			} catch (error) {
				console.log("error: ", error)
			}
		},

		async changeStage(number) {
			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro?`,
				text: `Se terminara el torneo en la etapa ${number}.`,
			})
			if (!confirm.value) return

			try {
				const datas = {
					stage_id: number,
				}
				const { data } = await tournamentService.changeStage(this.infoDiagram.id, datas)
				this.showToast("success", "top-right", "Terminar torneo", "SuccessIcon", data.message)
				await this.getTournament()
			} catch (error) {
				console.log("error: ", error)
			}
		},

		generateNullParticipants() {
			const temporaryParticipant = []
			for (let index = 1; index <= 16; index++) {
				temporaryParticipant.push({
					id: null,
					is_deleted: 0,
					name: null,
					participant_id: null,
				})
			}
			return temporaryParticipant
		},

		async assingWinner(participant) {
			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro?`,
				text: `Se asignara como ganador al participante ${participant.name}.`,
			})
			if (!confirm.value) return

			try {
				const { data } = await tournamentService.selectWinner(this.infoDiagram.id, participant)
				this.showToast("success", "top-right", "Seleccion de Ganador", "SuccessIcon", data.message)
				await this.getTournament()
			} catch (error) {
				console.log("error: ", error)
			}
		},
		async getTournament() {
			this.isPreloading()
			try {
				const { data } = await tournamentService.getParticipantsTournamentDiagram(this.infoDiagram.id)
				const datas = data.confrontations
				this.tournament = [{ ...datas }]
				this.tournament2 = [{ ...datas }]
				this.dates = data.last_update
				this.avatar = data.avatar
				this.stage = data.end_stage

				// this.tournament.push(data)
			} catch (error) {
				this.isPreloading(false)
			} finally {
				this.isPreloading(false)
			}
		},

		async getByParticipants() {
			this.isPreloading()
			try {
				const { data } = await tournamentService.getbyDate(this.infoDiagram.id)
				await Promise.all(
					data.slice(0, 16).map(async (item, index) => {
						const participantWithIndex = { ...item }
						const findIndex = this.participant.findIndex((item2, index2) => index2 == index)
						this.$set(this.participant, findIndex, participantWithIndex)
					})
				)
				this.isPreloading(false)
			} catch (error) {
				console.error("Error al obtener los datos:", error)
				this.isPreloading(false)
			}
		},

		onDragPart(participant, event) {
			this.isActiveAllDrag = true
			this.participantMove.action = "add"
			event.item.classList.add("zIndex")
			this.participantMove.participant = { ...participant }
		},
		handleEndPart(event, participant) {
			this.isActiveAllDrag = false
			event.item.classList.remove("zIndex")
			event.item.classList.remove("sdsd")
		},

		onDrag(participant, event) {
			this.isActiveAllDrag = true
			if (participant.is_played === 1 || !participant.participant_id || this.tab !== 0) {
				event.preventDefault()
				return
			}

			this.participantMove.action = "move"
			this.participantMove.participant = { ...participant }
			event.item.classList.add("sdsd")
		},

		handleEnd(event, participant) {
			this.isActiveAllDrag = false
			event.item.classList.remove("sdsd")
		},

		async onDrop(event, oldParticipant) {
			const newParticipant = this.participantMove.participant
			const action = this.participantMove.action

			if (action == "add" && oldParticipant.id != null) {
				this.showToast(
					"warning",
					"top-right",
					"Cambiar posicion",
					"AlertCircleIcon",
					"La posicion que quiere cambiar ya esta ocupado por un participante."
				)
				this.refreshAll()
				return
			}

			if (action == "move" && (oldParticipant.is_played == 1 || newParticipant.id == oldParticipant.id)) {
				this.showToast(
					"warning",
					"top-right",
					"Cambiar posicion",
					"AlertCircleIcon",
					"La posicion que quiere cambiar ya esta ocupado por un participante que ya jugo."
				)
				this.refreshAll()
				return
			}
			if (action == "move") {
				await this.changePositionOfParticipant(oldParticipant, newParticipant)
			} else {
				await this.selectParticipant(
					{ id: newParticipant.participant_id, name: newParticipant.name },
					oldParticipant,
					true
				)
			}
		},
		
		async changePositionOfParticipant(oldParticipant, newParticipant) {
			this.isLoading = true

			try {
				const { data } = await tournamentService.changePositionOfParticipant(this.infoDiagram.id, {
					old_participant: oldParticipant,
					new_participant: newParticipant,
				})
				this.showToast("success", "top-right", "Cambio de Posicion", "SuccessIcon", data.message)
				await this.getTournament()
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isLoading = false
			}
		},

		async exportToPDF() {
			const customFormat = [400, 340] // Ancho x Alto en milímetros

			this.isPreloading()
			this.hideIcons = true
			const content = this.$refs.diagramContent
			const wrapper = this.$refs.wrapper
			const imagenStyle = this.$refs.imagenStyle

			// Oculta elementos no deseadosf
			const hiddenElements = content.querySelectorAll(".not-for-capture")
			const hiddenElemnt = content.querySelectorAll(".not-capture")
			hiddenElements.forEach((el) => (el.style.display = "none"))
			hiddenElemnt.forEach((el) => (el.style.display = "none"))
			const originalPadding = wrapper
			const padding = content
			imagenStyle.classList.remove("imageprev")
			imagenStyle.classList.add("pdfprev")
			padding.style.padding = "1.5rem"

			try {
				// setTimeout(async () => {
				originalPadding.style.justifyContent = "center"
				originalPadding.style.padding = "3rem"
				const options = {
					filename: "tournament.pdf",
					image: { type: "jpeg", quality: 1 },
					html2canvas: { scale: 2 },
					jsPDF: { unit: "mm", format: customFormat, orientation: "landscape" },
				}

				await html2pdf().set(options).from(content).save()
				this.hideIcons = false
				// }, 100)
			} catch (error) {
				console.error("Error al exportar a PDF:", error)
			} finally {
				hiddenElements.forEach((el) => (el.style.display = ""))
				hiddenElemnt.forEach((el) => (el.style.display = ""))
				padding.style.padding = "0rem"
				imagenStyle.classList.add("imageprev")
				imagenStyle.classList.remove("pdfprev")
				this.isPreloading(false)
			}
		},

		async captureAsImage() {
			this.isPreloading()
			const content = this.$refs.diagramContent
			const wrapper = this.$refs.wrapper
			// Oculta elementos no deseados
			const hiddenElements = content.querySelectorAll(".not-for-capture")
			hiddenElements.forEach((el) => (el.style.display = "none"))
			const hiddenElemnt = content.querySelectorAll(".not-capture")
			hiddenElemnt.forEach((el) => (el.style.display = "none"))
			// Guarda el margen original para restaurarlo más tarde
			// Aplica el margen izquierdo antes de la captura

			content.style.alighItems = "center"
			content.style.paddingLeft = "1.5rem"
			content.style.paddingTop = "1.5rem"
			wrapper.style.justifyContent = "center"
			wrapper.style.paddingTop = "3rem"
			content.style.width = "100%"
			wrapper.style.width = "100%"

			console.log(content)

			const textElements = content.querySelectorAll("*")
			textElements.forEach((el) => {
				if (
					el.tagName === "P" ||
					el.tagName === "SPAN" ||
 					el.tagName === "H2" ||
					el.tagName === "H3" ||
 					el.tagName === "H5" ||
					el.tagName === "H6"
				) {
					const fontSize = window.getComputedStyle(el).fontSize
					el.style.fontSize = `calc(${fontSize} + .09rem)`
				}
			})

			try {
				const canvas = await html2canvas(content, {
					scale: 2, // Aumentar el factor de escala
					width: 1510, // Establecer el ancho deseado
					height: 1220, // Establecer la altura deseada
				})
				const imageURL = canvas.toDataURL("image/png")
				const link = document.createElement("a")
				link.href = imageURL
				link.download = "exported-image.png"
				link.click()
			} catch (error) {
				console.error("Error capturando imagen:", error)
			} finally {
				this.isPreloading(false)
				hiddenElements.forEach((el) => (el.style.display = ""))
				hiddenElemnt.forEach((el) => (el.style.display = ""))

				content.style.paddingLeft = "0px"
				content.style.paddingRight = "0px"
				content.style.paddingTop = "0px"
				wrapper.style.justifyContent = "start"
				textElements.forEach((el) => {
					if (
						el.tagName === "P" ||
						el.tagName === "SPAN" ||
 						el.tagName === "H2" ||
						el.tagName === "H3" ||
 						el.tagName === "H5" ||
						el.tagName === "H6"
					) {
						el.style.fontSize = "10px"
					}
				})
			}
		},
		debounceInput: _.debounce(function (e) {
			this.name = e.target.value
		}, 500),
		addParticipant(participant) {
			this.$set(participant, "addP", true)
		},
		async fetchOptionsSpecimens(search, participant) {
			clearTimeout(this.debounce)
			this.debounce = setTimeout(async () => {
				// loading(true)
				if (search.length > 1) {
					this.$set(participant, "loading", true)
					await this.getSelectParticipants(search, participant)
					this.$set(participant, "loading", false)
				}
				// loading(false)
			}, 300)
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 1040
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = "300px"
			dropdownList.style.fontSize = "10px"
			dropdownList.style.fontWeight = "700"
		},
		async getSelectParticipants(search, participant) {
			this.isLoading = true
			const params = {
				search: search,
			}
			const { data } = await tournamentService.getParticipant(params)
			// this.optionParticipant = data
			this.$set(participant, "optionParticipant", data)
			this.isLoading = false
		},
		async selectParticipant(selected, participant, isAdd = false) {
			this.isPreloading()
			try {
				const params = {
					id: null,
					stage_id: participant.stage_id,
					group: participant.group,
					alignment: participant.alignment,
					participant_id: selected.id,
					name: selected.name,
					is_played: participant.is_played,
					is_winner: participant.is_winner,
					parent_id: null,
				}
				const data = await tournamentService.setParticipantFromDiagram(this.infoDiagram.id, params)
				this.showToast("success", "top-right", "Agregar participante", "SuccessIcon", data.message)
				if (isAdd) {
					this.participant.map((it) => {
						if (it.id == this.participantMove.participant.id) {
							this.$set(it, "in_use", 1)
						}
					})
				}
				await this.getTournament()
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isPreloading(false)
			}
		},
		cancelAddParticipant(participant) {
			this.$set(participant, "addP", false)
			this.$set(participant, "optionParticipant", [])
			this.$set(participant, "selected", null)
		},
	},
}
</script>
<style lang="scss" scoped>
$side-margin: 50px;
$vertical-margin: 10px;

.container-participants {
	width: 400px;
	height: 300px;
	border: 1px solid salmon;
}

.wrapper {
	display: flex;
	height: 988px;
	justify-content: center;
	margin-right: 1.3rem;
	min-width: 1085px;
	position: relative;
	z-index: 2;
}

.champions {
	font-size: 1.2rem;
	color: #7367f0;
	font-weight: 900;
	position: absolute;
	top: 150%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.champion-top {
	top: 9.2rem !important;
}

.champion-tops {
	top: 18rem !important;
}
.champion-quartos {
	top: 5rem !important;
}

.img-winner {
	position: absolute;
	top: 43%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	width: 90px;
	height: 90px;
}
.container-texts {
	padding: 7px;
	margin: 0;
	width: 170px;
	height: 46px;
	font-size: 10px;
	font-weight: 700;
	background-color: #d9d9d9;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 2;
	&.active {
		border: 2px solid #7367f0;
		justify-content: center !important;
		text-align: center;
	}
	&.transparents {
		background-color: #f0f0f0 !important;
	}
}

.parent-winner {
	padding: 10px;
	margin: 0;
	width: 170px;
	height: 44px;
	font-size: 10px;
	font-weight: 900;
	background-color: #d9d9d9;
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	&.active {
		border: 2px solid #7367f0;
		justify-content: center !important;
		text-align: center;
	}
	&.transparents {
		background-color: #f0f0f0;
	}
}

//participant
.sdsd {
	overflow: hidden;
	// position: absolute;
	// top: 0;
}
.item-child-participant {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	width: 183px;
	height: 46px;
	// padding-bottom: $vertical-margin;
	// padding-bottom: $vertical-margin;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		background-color: #ebe9f1;
		right: 0;
		top: 50%;
		transform: translateX(100%);
		width: 25px;
		height: 2px;
	}
	&.has-label:before {
		background-color: #28c76f;
	}
	&.defeat:before {
		background-color: #ebe9f1 !important;
	}
	&:after {
		content: "";
		position: absolute;
		background-color: #ebe9f1;
		right: -$side-margin / 2;
		height: calc(10% + 21px);
		width: 2px;
		top: 50%;
	}
	&.has-label:after {
		background-color: #28c76f;
		height: calc(40% + 8px);
	}

	&.defeat:after {
		background-color: #ebe9f1 !important;
	}
	&:last-child {
		&:after {
			transform: translateY(-100%);
		}
	}
	&:only-child:after {
		display: none;
	}
}

.item-child-quartos {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	// padding-bottom: $vertical-margin;
	// padding-bottom: $vertical-margin;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		background-color: #ebe9f1;
		right: 0;
		top: 50%;
		transform: translateX(100%);
		width: 25px;
		height: 2px;
	}
	&.has-label:before {
		background-color: #28c76f;
	}
	&.defeat:before {
		background-color: #ebe9f1 !important;
	}

	&:after {
		content: "";
		position: absolute;
		background-color: #ebe9f1;
		right: -$side-margin / 2;
		height: calc(32% + 21px);
		width: 2px;
		top: 50%;
	}
	&.has-label:after {
		background-color: #28c76f;
		height: calc(45% + 7px);
	}
	&.defeat:after {
		background-color: #ebe9f1 !important;
	}
	&:last-child {
		&:after {
			transform: translateY(-100%);
		}
	}
	&:only-child:after {
		display: none;
	}
}

.item-child-semifinal {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	// padding-bottom: $vertical-margin;
	// padding-bottom: $vertical-margin;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		background-color: #ebe9f1;
		right: 0;
		top: 50%;
		transform: translateX(100%);
		width: 25px;
		height: 2px;
	}
	&.has-label:before {
		background-color: #28c76f;
	}
	&.defeat:before {
		background-color: #ebe9f1 !important;
	}

	&:after {
		content: "";
		position: absolute;
		background-color: #ebe9f1;
		right: -$side-margin / 2;
		height: calc(41% + 21px);
		width: 2px;
		top: 50%;
	}
	&.has-label:after {
		background-color: #28c76f;
		height: calc(48% + 7px);
	}
	&.defeat:after {
		background-color: #ebe9f1 !important;
	}
	&:last-child {
		&:after {
			transform: translateY(-100%);
		}
	}
	&:only-child:after {
		display: none;
	}
}

.item-child-final {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	// padding-bottom: $vertical-margin;
	// padding-bottom: $vertical-margin;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		background-color: #ebe9f1;
		right: 0;
		top: 50%;
		transform: translateX(100%);
		width: 25px;
		height: 2px;
	}
	&.has-label:before {
		background-color: #28c76f;
	}
	&.defeat:before {
		background-color: #ebe9f1 !important;
	}
	&:after {
		content: "";
		position: absolute;
		background-color: #ebe9f1;
		right: -$side-margin / 2;
		height: calc(46% + 21px);
		width: 2px;
		top: 50%;
	}
	&.has-label:after {
		background-color: #28c76f;
		height: calc(48% + 10px);
	}
	&.defeat:after {
		background-color: #ebe9f1 !important;
	}

	&:last-child {
		&:after {
			transform: translateY(-100%);
		}
	}
	&:only-child:after {
		display: none;
	}
}

.item {
	display: flex;
	flex-direction: row-reverse;
	p {
		padding: 10px;
		margin: 0;
		width: 170px;
		height: 41px;
		font-size: 12px;
		font-weight: 500;
		background-color: #d9d9d9;
	}

	&-parent {
		position: relative;
		margin-left: 60px;
		display: flex;
		align-items: center;
		&:after {
			position: absolute;
			content: "";
			width: 37.5px;
			height: 2px;
			left: 0;
			top: 50%;
			transform: translateX(-100%);
			background-color: #ebe9f1;
		}
		&.check:after {
			background-color: #28c76f;
		}
		&.deleteCheck::after {
			background-color: #ebe9f1 !important;
		}
	}
	&-childrens {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
.container-diagram {
	background-color: #ffffff;
}
.container-text {
	display: flex;
	min-width: 1472px;
	gap: 0.7rem;
	// border-bottom: 5px solid #8095ff;
	border-radius: 0px 15px 15px px;
	position: relative;
	justify-content: space-between;
}

.line-text {
	height: 5px;
	background-color: rgba(128, 149, 255, 1);
	position: absolute;
	bottom: 0px;
	width: 100%;
	border-radius: 0px 15px 15px 15px;
}
.container-ul {
	margin-top: 1rem;
	padding-left: 1.5rem;
	font-weight: 300;
}
p {
	text-transform: lowercase;
	font-weight: 400;
}
p::first-letter {
	text-transform: uppercase;
}
.Draggable-container {
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	gap: 0.3rem;
}

.alignment {
	width: 11px;
	height: 100%;
	background-color: rgba(128, 149, 255, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-weight: 900;
	font-size: 10px;
}

.line {
	width: 18px;
	height: auto;
	background-color: rgba(128, 149, 255, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-weight: 900;
	font-size: 13px;
}
.Draggable-style {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.winners {
	border: 4.92px solid #7367f0;
}

.champion {
	position: absolute;
	right: -15px;
	top: 50.9%; /* ajusta el valor según sea necesario */
	left: -13%;
}
.champion1 {
	position: absolute;
	right: -15px;
	top: 50.9%; /* ajusta el valor según sea necesario */
	right: -13%;
}

.containers-alert {
	min-width: 1085px;
	height: 0px;
	display: flex;
	justify-content: end;
	margin-top: 3rem;
	margin-right: 2rem;
	min-width: 1472px;
}

.container-alert {
	position: absolute;
	width: 350px;
	// margin-right: 1rem;
	// margin-top: 1.8rem;
	// right: 0px;
	// top: 165px;
	// border-top: 5px solid rgba(128, 149, 255, 1);
	background-color: rgba(223, 228, 255, 1);
	padding: 1rem 0.5rem 0.3rem 1rem;
	border-radius: 0.5rem;

	h4 {
		color: white;
		font-weight: 800;
	}
	span {
		color: white;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 0.9rem;
	}
	top: 14rem;
}

.line-alert {
	position: absolute;
	top: 0px;
	width: 96.5%;
	left: 5px;
	height: 5px;
	background-color: rgba(128, 149, 255, 1);
	border-radius: 5px 5px 0px 0px;
	transform: rotate(180deg);
}

.container-logo {
	background-color: #8095ff;
	border-radius: 5px 15px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 318px;
	height: 155px;

	img {
		width: 248.25px;
		height: 79.11px;
		object-fit: contain;
	}
}

.container-logotext {
	width: 79%;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	padding-left: 2.5rem;
	border-radius: 15px 3px 3px 0px;
	height: 142px;
	// margin-bottom: .5rem;
}

.pdfprev {
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.imageprev {
	position: absolute;
	top: 90%;
	left: 200%;
	transform: translate(-50%, -50%);

	@media (min-width: 768px) {
		top: 80%;
		left: 135%;
		transform: translate(-50%, -50%);
	}

	@media (min-width: 1024px) {
		top: 110%;
		left: 100%;
		transform: translate(-50%, -50%);
	}
	@media (min-width: 1200px) {
		top: 67%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
//

.containerss {
	background-color: rgba(128, 149, 255, 1);
	border-radius: 10px 10px 0px 0px;
}
.not-for-capture {
	border: 3px solid rgba(128, 149, 255, 1);
	border-radius: 16px 16px 16px 16px;
	// overflow: hidden;
	margin-top: 1.8rem;
	max-height: 50vh;
	min-width: 300px;
}
.container-div {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-top: 0.5rem;
	max-height: 41vh;
	overflow: auto;
	padding-bottom: 0.5rem;
}

.container-div::-webkit-scrollbar {
	width: 10px; /* Ancho del scrollbar */
}

.container-div::-webkit-scrollbar-track {
	background: #f1f1f1; /* Color del fondo del scrollbar */
}

.container-div::-webkit-scrollbar-thumb {
	background: rgba(128, 149, 255, 1) !important; /* Color del thumb (la parte que se mueve) */
}

.radio-button {
	position: absolute;
	top: -2rem;
	left: 0.1rem;
	background-color: #e3e3e3;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.radio-button-icons {
	width: 10px;
	height: 10px;
	border-radius: 100%;
	&.active {
		background-color: #7367f0 !important;
	}
	&.desactive {
		background-color: rgb(175, 173, 173) !important;
	}
}
.liness {
	width: 34px;
	height: 13px;
	position: absolute;
	bottom: 0;
	right: 22.15rem;
}
.container-sticky {
	position: sticky;
	top: 0;
	z-index: 10;
}
.delete-participant {
	position: absolute;
	top: 1rem;
	right: -1.7rem;
	background-color: rgb(234, 84, 85);
	border-radius: 0.3rem;
	padding: 0.2rem 0.4rem !important;
}
.zIndex {
	z-index: 9999999;
	font-size: 10px;
	height: 46px;
	color: #6e6b7b;
	font-weight: 700;
	// position: absolute;
	overflow: hidden;
}
</style>
