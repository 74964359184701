<template>
	<b-modal size="lg" centered hide-footer :title="creationModal.title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="creationModal.loading">
			<b-row>
				<template v-for="(specimen, j) in creationModal.specimens">
					<b-col class="mb-1" cols="12" md="6" xl="4" :key="j" v-if="!specimen.delete">
						<div class="card position-relative hoverTrigger flex-column mb-1">
							<SpecimenPlate :specimen="specimen" />
							<div
								class="text-center mt-25 w-100"
								:class="
									specimen.result_id == 1
										? 'bg-success'
										: specimen.result_id
										? 'bg-primary'
										: 'bg-danger'
								"
							>
								<small class="font-weight-bold mt-25 text-white text-uppercase text-center">
									{{ specimen.result }}
								</small>
							</div>
							<b-badge class="text-uppercase mt-25 w-100" variant="secondary">
								{{ specimen.status_name }}
							</b-badge>
							<div style="position: absolute; top: 0; right: 0" v-if="specimen.status_id != 3">
								<b-button
									v-if="!specimen.nursing_status_id"
									v-b-tooltip.hover
									title="Enviar ejemplar a enfermeria"
									class="btn-icon"
									size="sm"
									variant="flat-danger"
									@click="
										;(addNursery.info = { specimen: specimen }),
											(addNursery.show = !addNursery.show)
									"
								>
									<v-icon name="md-healthandsafety-outlined" scale="1" class="text-danger" />
								</b-button>
								<div
									class="mt-50"
									v-b-tooltip.hover
									title="Ejemplar en espera"
									v-if="specimen.nursing_status_id == 1"
								>
									<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
								</div>
								<div
									class="mt-50"
									v-b-tooltip.hover
									title="Ejemplar en proceso"
									v-else-if="specimen.nursing_status_id == 2"
								>
									<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
								</div>
							</div>
						</div>
					</b-col>
				</template>
				<b-col class="text-center my-2" v-if="creationModal.specimens.length == 0">
					No hay ejemplares por mostrar
				</b-col>
			</b-row>

			<div class="mt-1 mb-1">
				<h5 class="mb-0">Enfermería</h5>

				<div class="d-flex align-items-center justify-content-start mt-50">
					<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-danger" />
					<small class="mb-0 ml-25">Enviar ejemplar a enfermeria</small>
				</div>

				<div class="d-flex align-items-center justify-content-start mt-50">
					<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
					<small class="mb-0 ml-25">Ejemplar en espera</small>
				</div>

				<div class="d-flex align-items-center justify-content-start mt-50">
					<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
					<small class="mb-0 ml-25">Ejemplar en proceso</small>
				</div>
			</div>
		</b-overlay>

		<AddSpecimensNursery
			:show="addNursery.show"
			:info="addNursery.info"
			@closing="addNursery.show = false"
			@refresh="init"
		/>
	</b-modal>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import tournamentService from "@/services/tournament/default.service"
import moment from "moment"
import AddSpecimensNursery from "@/views/amg/nursery/components/AddSpecimensNursery.vue"

export default {
	components: { SpecimenPlate, AddSpecimensNursery },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		//
		creationModal: {
			show: false,
			loading: false,
			title: null,
			search: null,
			frontIdx: null,
			specimens: [],
		},
		addNursery: { show: false, info: {} },
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.clearCreationModal()
			this.$emit("closing")
		},
		clearCreationModal() {
			this.creationModal = {
				show: false,
				loading: false,
				title: null,
				search: null,
				opts: [],
				frontIdx: null,
				specimens: [],
			}
		},
		async init() {
			this.creationModal.loading = true
			const { data } = await tournamentService.getSpecimensToEvent({ id: this.info.id })
			if (this.info._status == "qualified") {
				// this.creationModal.title = `Jugaron / ${moment(this.info.date).format("MM-DD-YYYY")}`;
				this.creationModal.title = `Ejemplares / ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
				this.creationModal.specimens = data.filter((it) => it.status_tournament_event_id == 1)
			} else {
				// this.creationModal.title = `Registrados / ${moment(this.info.date).format("MM-DD-YYYY")}`;
				this.creationModal.title = `Ejemplares / ${this.info.tournament} / ${this.info.coliseum} / ${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
				this.creationModal.specimens = data
			}
			this.creationModal.loading = false
		},
	},
	watch: {
		async show(val) {
			if (val) {
				this.isActive = val
				this.init()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.hoverTrigger {
	padding: 0.5rem;
	height: 130px;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
