<template>
	<b-modal centered no-close-on-backdrop size="lg" title="Agregar fecha(s)" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<h2 class="title-form">1. Informacion general</h2>
				<!-- aqui -->
				<b-row>
					<b-col cols="12" md="6">
						<b-form-group label="Torneo">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									class="border-primary rounded"
									label="name"
									placeholder="Seleccionar"
									:clearable="false"
									:options="tournamentOpts"
									:reduce="(opt) => opt.id"
									v-model="tournament"
									@input="getStageOpts"
									:disabled="info ? true : false"
								>
							
								<template v-slot:option="option">

									<img :src="option.url_thumb" alt="" style="width: 30px; height: 30px; border-radius: 50%; margin-right: .5rem;">
									<span>{{option.name}}</span>
									 
								</template>
							
							</v-select>
								<small class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3">
						<b-form-group label="Etapa">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									class="border-primary rounded"
									label="name"
									placeholder="Seleccionar"
									:clearable="false"
									:options="stageOpts"
									:reduce="(opt) => opt.id"
									v-model="stage"
									@input="getSubStageOpts"
									:disabled="tournament == null || info ? true : false"
								/>
								<small class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</small>
							</ValidationProvider>
							<div class="text-center w-100" v-if="info && !stageOpts.find((it) => it.id == stage)">
								<small class="text-danger text-center">No hay etapa disponible</small>
							</div>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="3">
						<feather-icon
							class="text-primary clickable float-right"
							icon="Edit3Icon"
							size="20"
							@click="stageTypesModal.show = !stageTypesModal.show"
							v-if="!!tournament"
						/>
						<b-form-group>
							<template #label>
								<div>
									<span>Tipo de etapa</span>
								</div>
							</template>

							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									class="border-primary rounded"
									label="code"
									placeholder="Seleccionar"
									:clearable="false"
									:options="stageTypeOpts"
									:reduce="(opt) => opt.id"
									:disabled="stage == null"
									v-model="stageType"
									@input="selectSubStage"
									:selectable="(opt) => (!disabled_price_zero ? opt.price > 0 : true)"
								>
									<template #option="{ code, required }">
										<div class="selected d-center d-flex align-items-center">
											<p class="mb-0">{{ code }} / {{ required }} G.</p>
										</div>
									</template>
									<template #selected-option="{ code, required }">
										<div class="selected d-center d-flex align-items-center">
											<p class="mb-0">{{ code }} / {{ required }} G.</p>
										</div>
									</template>
								</v-select>
								<small class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-form-group>
					</b-col>
				</b-row>
				<b-alert
					v-show="stageTypeOpts.filter((it) => !disabled_price_zero && it.price == 0).length > 0"
					show
					variant="warning"
					class="p-1"
				>
					<p style="font-size: 12px">NO ES POSIBLE SELECCIONAR UN TIPO DE ETAPA CON COSTO S./ 0.00</p>
				</b-alert>

				<b-table-simple small sticky-header="20rem">
					<b-thead class="text-center text-nowrap">
						<b-tr>
							<b-th style="max-width: 5rem">Gallos</b-th>
							<b-th>Costo</b-th>
							<b-th>Tipo</b-th>
							<b-th>Metodo</b-th>
							<b-th>Peso</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-if="curStageType">
							<b-td style="max-width: 5rem">{{ curStageType.required }}</b-td>
							<b-td>S./ {{ Number(curStageType.price).toFixed(2) }}</b-td>
							<b-td>{{ curStageType.type_name }}</b-td>
							<b-td>{{ curStageType.method }}</b-td>
							<b-td>{{ curStageType.weight ? `${curStageType.weight}Kg` : "---" }}</b-td>
						</b-tr>
						<b-tr v-else>
							<b-td colspan="5">Seleccione un tipo de etapa</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<h2 class="title-form" v-if="tournament && stage && stageType">
					2. Fechas
					<b-button
						v-if="!info"
						class="btn-icon ml-1 p-50"
						variant="flat-success"
						size="sm"
						@click="addEvent"
					>
						<feather-icon icon="PlusIcon" size="20" />
					</b-button>
				</h2>

				<b-table-simple v-if="tournament && stage && stageType" sticky-header="20rem">
					<b-thead class="text-center">
						<b-tr>
							<b-th style="width: 3rem !important">Fecha</b-th>
							<b-th>Coliseo</b-th>
							<b-th style="width: 10px">Frente(s)</b-th>
							<b-th class="">Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="(event, i) in events" :key="i">
							<b-td>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<date-picker
										:class="errors[0] ? 'border-danger' : ''"
										style="min-width: 4rem; width: 11rem"
										placeholder="Seleccionar fecha"
										:disabled-date="(opt) => optDate(opt)"
										v-model="event.date"
									></date-picker>
								</ValidationProvider>
							</b-td>
							<b-td class="">
								<v-select
									style="width: 12rem"
									class="border-primary rounded"
									label="name"
									placeholder="Seleccionar coliseo"
									:clearable="false"
									:options="coliseumOpts"
									:reduce="(opt) => opt.id"
									v-model="event.coliseum"
								/>
							</b-td>
							<b-td class="">
								<ValidationProvider rules="required|zero" v-slot="{ errors }">
									<div class="d-flex align-items-center justify-content-center">
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model="event.fronts"
											:disabled="info ? true : false"
										/>
									</div>
								</ValidationProvider>
							</b-td>
							<b-td class="m-25 p-25">
								<b-button
									v-if="i == 0 && info && event.fronts > 1"
									variant="outline-danger"
									size="sm"
									style="margin-right: 5px"
									@click="desegregateDatesFronts"
								>
									<feather-icon icon="ScissorsIcon" class="" />
								</b-button>
								<b-button
									v-if="!info || (info && i > 0)"
									class="btn-icon p-50"
									variant="outline-danger"
									size="sm"
									@click="removeEvent(i)"
								>
									<feather-icon icon="TrashIcon" size="20" />
								</b-button>
							</b-td>
						</b-tr>
						<b-tr v-if="events.length == 0">
							<b-td colspan="5">No hay registros para mostrar</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="primary" @click="createEvent">Guardar</b-button>
		</template>
		<StageTypes
			:show="stageTypesModal.show"
			:info="stageTypesModal.info"
			@closing="stageTypesModal.show = false"
			@new-types="setStages"
		/>
	</b-modal>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import moment from "moment"
import useCalendar from "@/views/amg/tournaments/v2/components/calendar/useCalendar.js"

import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import StageTypes from "./StageTypes.vue"

export default {
	components: { DatePicker, StageTypes },
	props: {
		season: Object,
		show: Boolean,
		info: Object,
		disabled_price_zero: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tournamentOpts: [],
		stageOpts: [],
		stageTypeOpts: [],
		coliseumOpts: [],
		//
		tournament: null,
		stage: null,
		stageType: null,
		//
		events: [],
		stageTypesModal: {
			show: false,
			info: { stages: [] },
		},
		beforeEdit: null,
	}),
	computed: {
		curStageType() {
			if (!!!this.stageType) return null
			let match = this.stageTypeOpts.find((t) => t.id == this.stageType)
			return match ? match : null
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.stageOpts = []
			this.stageTypeOpts = []
			this.coliseumOpts = []
			this.tournament = null
			this.stage = null
			this.stageType = null
			this.events = []
			this.beforeEdit = null
			this.$emit("closing")
		},
		async getTournaments() {
			this.isLoading = true
			const { data } = await tournamentService.getTournamentOpts({
				start_date: this.season.start,
				end_date: this.season.end,
			})
			this.tournamentOpts = data.data
			this.methodOpts = [
				{ name: "AL PESO", id: 2 },
				{ name: "TAPADA", id: 1 },
			]
			if (this.info) {
				this.tournament = this.tournamentOpts.find((it) => it.id == this.info.data.tournament_id).id
				await this.getStageOpts()
			}
			this.isLoading = false
			// reasignar valores previos
			if (this.beforeEdit && this.beforeEdit.tournament) {
				this.tournament = this.beforeEdit.tournament
				this.getStageOpts()
			}
		},
		async getStageOpts() {
			this.isLoading = true

			// coliseos
			const { data: c } = await tournamentService.getColiseumByTournament({
				tournament_id: this.tournament,
			})
			this.coliseumOpts = c.data

			// etapas
			const { data } = await tournamentService.getStagesByTournament({ tournament_id: this.tournament })
			this.stageOpts = data.data

			this.stage = null
			this.stageType = null
			if (data.data.length == 1 && !this.info) {
				this.stage = data.data[0].id
			}
			if (this.info) {
				this.stage = this.info.data.stage_id
				if (!this.stageOpts.find((it) => it.id == this.stage)) {
					this.showToast("warning", "top-right", "Torneos", "WarningIcon", "No hay etapa disponible")
					this.stage = null
					return
				}
			}
			// reasignar valores previos
			if (this.beforeEdit && this.beforeEdit.stage && this.stageOpts.some((s) => s.id == this.beforeEdit.stage)) {
				this.stage = this.beforeEdit.stage
			}

			// asignar stageOpts para edicion
			let curTournament = this.tournamentOpts.find((opt) => opt.id == this.tournament)
			if (curTournament && curTournament.stages) {
				this.stageTypesModal.info = {
					stages: curTournament.stages.map((s) => ({ ...s.data, id: s.id })),
					_mode: "edit",
				}
			}

			this.getSubStageOpts()

			this.isLoading = false
		},
		async getSubStageOpts() {
			if (!this.stage) return // cuando getStageOpts llama sin lograr setear el stage
			this.isLoading = true
			const { data } = await tournamentService.getSubStages({ tournament_id: this.tournament, stage: this.stage })
			this.stageTypeOpts = data.map((it) => ({ ...it, ...it.data, data: null }))
			this.stageType = null
			this.events = []
			if (this.stageTypeOpts.length == 1 && !this.disabled_price_zero && this.stageTypeOpts[0].price > 0) {
				this.stageType = this.stageTypeOpts[0].id
				this.addEvent()
			}
			this.isLoading = false
			// reasignar valores previos
			if (
				this.beforeEdit &&
				this.beforeEdit.stageType &&
				this.stageTypeOpts.some((t) => t.id == this.beforeEdit.stageType)
			) {
				this.stageType = this.beforeEdit.stageType
				this.beforeEdit = null
			}
		},
		addEvent() {
			if (!this.tournament) {
				this.showToast("warning", "top-right", "Torneos", "WarningIcon", "Debe elegir un torneo")
				return
			}
			let curTournament = null // cuando es stage final, asignar fecha final por defecto
			if (this.stage == 4) curTournament = this.tournamentOpts.find((t) => t.id == this.tournament)
			let base = {
				date: curTournament ? new Date(curTournament.end_date) : null,
				fronts: this.info ? this.info.data.quantity_fronts : 1,
				coliseum: this.coliseumOpts.find((it) => it.main).id,
			}
			this.events.push(base)
		},
		desegregateDatesFronts() {
			const { date, fronts, coliseum } = this.events[0]

			if (fronts > 1) {
				let base = {
					date,
					fronts: 1,
					coliseum,
				}
				this.events.push(base)
				this.events[0].fronts--
			}
			console.log("desegregando: ", fronts)
		},
		removeEvent(idx) {
			this.events.splice(idx, 1)
			if (this.info) this.events[0].fronts++
		},
		async createEvent() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isLoading = true
			await tournamentService.createEventMassive({
				events: this.events.map((it) => {
					return {
						tournament_id: this.tournament,
						date: moment(it.date).format("YYYY-MM-DD"),
						coliseum_id: it.coliseum,
						type_id: this.curStageType.type_id,
						stage_id: this.curStageType.stage_id,
						sub_stage_id: this.curStageType.id,
						method_id: this.curStageType.method_id,
						weight: this.curStageType.weight,
						specimens_required: this.curStageType.required,
						quantity_fronts: it.fronts,
						event_temporary_id: this.info ? this.info.id : null,
					}
				}),
			})
			this.counterTrigger = Symbol()
			this.handleHidden()
			this.$emit("refresh")
			this.isLoading = false
		},
		optDate(date) {
			const to = this.tournamentOpts.find((it) => it.id == this.tournament)
			let end = new Date(to.end_date)
			end = end.setDate(end.getDate() + 1)
			return !(new Date(date) >= new Date(to.start_date) && new Date(date) < end)
		},
		selectSubStage() {
			this.info && this.events.length == 0 && this.addEvent()
		},
		async setStages(stages) {
			this.isLoading = true
			const resp = await tournamentService.updateStagesByTournament({
				stages: stages.map((it) => ({ ...it, delete: it.delete ? 1 : 0 })),
				tournament_id: this.tournament,
			})
			// save selected opts
			this.beforeEdit = {
				tournament: this.tournament,
				stage: this.stage,
				stageType: this.stageType,
			}
			this.stageTypesModal.info = { stages, _mode: "edit" }
			this.stageOpts = []
			this.stageTypeOpts = []
			this.coliseumOpts = []
			this.tournament = null
			this.stage = null
			this.stageType = null
			this.events = []
			this.$emit("refresh")
			this.getTournaments()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getTournaments()
			}
		},
	},
	setup() {
		const { counterTrigger } = useCalendar()
		return { counterTrigger }
	},
}
</script>
<style scoped>
.title-form {
	font-family: "Rubik" !important;
	font-size: 20px;
}
</style>
